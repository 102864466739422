// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import AnodeSlick from '../shared/AnodeSlick';

// data stubs
import theme from '../../data/theme';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageAboutUs() {
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`About Us — ${theme.name}`}</title>
            </Helmet>

            <div
                className="about-us__image"
                style={{ backgroundImage: 'url("images/aboutus.jpg")' }}
            />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">We Solve Corrosion Problems.</h1>
                            <div className="about-us__text typography">
                                
                                <p>
                                    With our help, our clients keep their
                                    structures cathodically protected. Whether
                                    it's a gas station, bulk plant of other
                                    structure, we guide clients tot eh best
                                    answer for their budget. Corrosion is a
                                    Cancer and we have the Corrosion Doctor.
                                </p>
                                <p>
                                    Anode Systems Company was established in
                                    1984 founder and current CEO, Hans Schmoldt.
                                    He began his early career in corrosion
                                    prevention in the mid 1960’s as a technician
                                    for Schmoldt Engineering in Bartlesville,
                                    Oklahoma. After earning a B.S. in Geological
                                    Engineering and a B.S. in Geology from the
                                    University of Missouri-Rolla, Mr. Schmoldt
                                    relocated to Grand Junction Colorado and
                                    founded Anode Systems Company (formally
                                    Anode Supply Co.). Shortly thereafter, he
                                    secured international accreditation by the
                                    National Association of Corrosion Engineers
                                    (NACE) as a cathodic protection specialist.
                                </p>
                                <p>
                                    Mr. Schmoldt has since conducted 84 classes
                                    and seminars in 22 states as the featured
                                    keynote speaker on the subjects of anodes,
                                    cathodic protection, and corrosion control
                                    sponsored by NACE, the Environmental
                                    Protection Agency (EPA), Colorado Department
                                    of Health, and Colorado Ski Country USA. He
                                    has authored several technical articles in
                                    trade journals, and is also very active in
                                    numerous non-profit organizations.
                                </p>
                                <p>
                                    Today, Anode Systems Company continues to
                                    expand and modernize the science of cathodic
                                    protection, utilizing the most up-to-date
                                    technology to prevent failures and
                                    degradation of necessary infrastructures
                                    like that storage tanks, pipelines, and
                                    pressure vessels.
                                </p>
                            </div>
                            <div className="about-us__team">
                                <h2 className="about-us__team-title">
                                    Meat Our Team
                                </h2>
                                <div className="about-us__teammates teammates">
                                    <AnodeSlick {...slickSettings}>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img
                                                    src="images/teammates/hans.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="teammate__name">
                                                Hans K. Schmoldt
                                            </div>
                                            <div className="teammate__position text-muted">
                                                Owner / President <br /> NACE
                                                Certified Level 4 Cathodic
                                                Protection Specialist
                                            </div>
                                        </div>
                                        
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img
                                                    src="images/teammates/junior.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="teammate__name">
                                                Junior Fuentes
                                            </div>
                                            <div className="teammate__position text-muted">
                                                Lead Field Technician
                                            </div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img
                                                    src="images/teammates/abel.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="teammate__name">
                                                Abel Sanchez
                                            </div>
                                            <div className="teammate__position text-muted">
                                                Sales Engineer
                                            </div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img
                                                    src="images/teammates/diedra.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="teammate__name">
                                                Diedra Harvey
                                            </div>
                                            <div className="teammate__position text-muted">
                                                Bookkeeper
                                            </div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img
                                                    src="images/teammates/reinaldo.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="teammate__name">
                                                Reinaldo Fuentes
                                            </div>
                                            <div className="teammate__position text-muted">
                                                Wire Technician
                                            </div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img
                                                    src="images/teammates/jaime.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="teammate__name">
                                                Jaime Herrera
                                            </div>
                                            <div className="teammate__position text-muted">
                                                Fabrication & Design
                                            </div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img
                                                    src="images/teammates/jose.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="teammate__name">
                                                Jose Efrain Herrera
                                            </div>
                                            <div className="teammate__position text-muted">
                                                Fabrication & Design
                                            </div>
                                        </div>
                                        
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img
                                                    src="images/teammates/edgar.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="teammate__name">
                                                Edgar Herrera
                                            </div>
                                            <div className="teammate__position text-muted">
                                                Manufacturing Hand
                                            </div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img
                                                    src="images/teammates/efrain.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="teammate__name">
                                                Efrain Herrera Jr.
                                            </div>
                                            <div className="teammate__position text-muted">
                                                Manufacturing Hand
                                            </div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img
                                                    src="https://via.placeholder.com/448x600.png?text=ISAIAH%20IMAGE"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="teammate__name">
                                                Noah
                                            </div>
                                            <div className="teammate__position text-muted">
                                                Manufacturing Hand
                                            </div>
                                        </div>
                                    </AnodeSlick>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageAboutUs;
