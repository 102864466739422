// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
 
// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobileMenu from './mobile/MobileMenu';
import Quickview from './shared/Quickview';

// pages
import BlogPageCategory from './blog/BlogPageCategory';
import BlogPagePost from './blog/BlogPagePost';
import PageCompare from './shop/ShopPageCompare';
import PageRFQ from './shop/ShopPageRFQ';
import ShopPageCategory from './shop/ShopPageCategory';
import ShopPageProduct from './shop/ShopPageProduct';
import SitePageAboutUs from './site/SitePageAboutUs';
import SitePageContactUs from './site/SitePageContactUs';
import SitePageFaq from './site/SitePageFaq';
import SitePageNotFound from './site/SitePageNotFound';
import SitePageTerms from './site/SitePageTerms';
import SitePagePrivacy from './site/SitePagePrivacy';

// data stubs
import theme from '../data/theme';

// const categoryLayouts = [
//     [
//         '/shop/category-grid-3-columns-sidebar',
//         { columns: 3, viewMode: 'grid', sidebarPosition: 'start' },
//     ],
//     ['/shop/category-grid-4-columns-full', { columns: 4, viewMode: 'grid' }],
//     ['/shop/category-grid-5-columns-full', { columns: 5, viewMode: 'grid' }],
//     [
//         '/shop/category-list',
//         { columns: 3, viewMode: 'list', sidebarPosition: 'start' },
//     ],
//     [
//         '/shop/category-right-sidebar',
//         { columns: 3, viewMode: 'grid', sidebarPosition: 'end' },
//     ],
// ].map(([url, options]) => (
//     <Route
//         key={url}
//         exact
//         path={url}
//         render={(props) => (
//             <ShopPageCategory {...props} {...options} categorySlug="anodes" />
//         )}
//     />
// ));

// const productLayouts = [
//     ['/shop/product-standard', { layout: 'standard' }],
//     ['/shop/product-columnar', { layout: 'columnar' }],
//     ['/shop/product-sidebar', { layout: 'sidebar' }],
// ].map(([url, options]) => (
//     <Route
//         key={url}
//         exact
//         path={url}
//         render={(props) => (
//             <ShopPageProduct
//                 {...props}
//                 {...options}
//                 productSlug="hp-bagged-mag-anode"
//             />
//         )}
//     />
// ));

function Layout(props) {
    const { match, headerLayout, homeComponent } = props;
    
    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />
            
            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Switch>
                        <Route
                            exact
                            path={`${match.path}`}
                            component={homeComponent}
                        />

                        <Redirect exact from="/shop" to="/shop/catalog" />
                        <Route
                            exact
                            path="/shop/catalog"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/catalog/:categorySlug"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    categorySlug={
                                        props.match.params.categorySlug
                                    }
                                />
                            )}
                        />

                        {/* {categoryLayouts} */}

                        <Route
                            exact
                            path="/shop/products/:productSlug"
                            render={(props) => (
                                <ShopPageProduct
                                    {...props}
                                    layout="standard"
                                    productSlug={props.match.params.productSlug}
                                />
                            )}
                        />

                        {/* {productLayouts} */}

                        <Route exact path="/shop/rfq" component={PageRFQ} />
                        <Route
                            exact
                            path="/shop/compare"
                            component={PageCompare}
                        />

                        <Route
                            exact
                            path="/blog"
                            render={(props) => (
                                <BlogPageCategory
                                    {...props}
                                    layout="classic"
                                    sidebarPosition="end"
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/blog/:postId"
                            render={(props) => (
                                <BlogPagePost {...props} layout="full" />
                            )}
                        />

                        <Route
                            exact
                            path="/about"
                            component={SitePageAboutUs}
                        />

                        <Route
                            exact
                            path="/privacy-policy"
                            component={SitePagePrivacy}
                        />

                        <Route
                            exact
                            path="/contact-us"
                            component={SitePageContactUs}
                        />
                        <Route exact path="/faq" component={SitePageFaq} />
                        <Route exact path="/terms" component={SitePageTerms} />
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>
                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(['default', 'compact']),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
