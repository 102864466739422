// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import Departments from './Departments';
import Indicator from './Indicator';
import IndicatorSearch from './IndicatorSearch';
import NavLinks from './NavLinks';
import { Compare20Svg, RFQ20Svg, LogoSmallSvg } from '../../svg';

function NavPanel(props) {
    const { layout, rfq, compare } = props;

    let logo = null;
    let departments = null;
    let searchIndicator;

    if (layout === 'compact') {
        logo = (
            <div className="nav-panel__logo">
                <Link to="/">
                    <LogoSmallSvg />
                </Link>
            </div>
        );

        searchIndicator = <IndicatorSearch />;
    }

    if (layout === 'default') {
        departments = (
            <div className="nav-panel__departments">
                <Departments />
            </div>
        );
    }

    return (
        <div className="nav-panel">
            <div className="nav-panel__container container">
                <div className="nav-panel__row">
                    {logo}
                    {departments}
                    <div className="nav-panel__nav-links nav-links">
                        <NavLinks />
                    </div>
                    <div className="nav-panel__indicators">
                        {searchIndicator}
                        <Indicator
                            url="/shop/rfq"
                            value={rfq.length}
                            icon={<RFQ20Svg />}
                        />
                        <Indicator
                            url="/shop/compare"
                            value={compare.length}
                            icon={<Compare20Svg />}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

NavPanel.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

NavPanel.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    rfq: state.rfq,
    compare: state.compare,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanel);
