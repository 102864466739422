// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// data stubs
import posts from '../../data/blogPosts';
import AllPagesPDFViewer from '../../components/pdf/all-pages';

import ASCWhitePaper from '../../ASCWHITEPAPER.pdf';
import BlockPosts from '../blocks/BlockPosts';

export default function BlogPost(props) {
    const { layout, post } = props;

    const postClasses = classNames('post__content typography', {
        'typography--expanded': layout === 'full',
    });

    const relatedPostsList = posts.slice(0, 2).map((relatedPost) => (
        <div
            key={relatedPost.id}
            className="related-posts__item post-card post-card--layout--related"
        >
            <div className="post-card__image">
                <Link to="/">
                    <img src={relatedPost.image} alt="" />
                </Link>
            </div>
            <div className="post-card__info">
                <div className="post-card__name">
                    <Link to="/">{relatedPost.title}</Link>
                </div>
                <div className="post-card__date">{relatedPost.date}</div>
            </div>
        </div>
    ));

    const BlogView = () => (
        <>
            <div className="post__featured">
                <img src={`images/posts/post-${post.id}.jpg`} alt="" />
            </div>
            <div className={postClasses}>
                <p>{post.body}</p>
                {post.link && (
                    <p>
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={post.link}
                        >
                            <button type="button" className="btn btn-primary">
                                More information ...
                            </button>
                        </a>
                    </p>
                )}
            </div>
        </>
    );

    return (
        <div className={`block post post--layout--${layout}`}>
            <div
                className={`post__header post-header post-header--layout--${layout}`}
            >
                <div className="post-header__categories">
                    <Link to="/">Latest news</Link>
                </div>
                <h1 className="post-header__title">{post.title}</h1>
                <div className="post-header__meta">
                    <div className="post-header__meta-item">
                        By <Link to="/">Hans Schmoldt</Link>
                    </div>
                    <div className="post-header__meta-item">
                        <Link to="/">{post.date}</Link>
                    </div>
                </div>
            </div>
            {post.pdf ? (
                <AllPagesPDFViewer pdf={ASCWhitePaper} />
            ) : (
                <BlogView />
            )}

            <BlockPosts layout="list-sm" title="More Posts" posts={posts} />
        </div>
    );
}

BlogPost.propTypes = {
    layout: PropTypes.oneOf(['classic', 'full']),
};

BlogPost.defaultProps = {
    layout: 'classic',
};
