// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import Indicator from '../header/Indicator';
import {
    Menu18x14Svg,
    LogoSmallSvg,
    Search20Svg,
    RFQ20Svg,
    Compare20Svg,
} from '../../svg';
import { mobileMenuOpen } from '../../store/mobile-menu';
import Search from '../header/Search';

class MobileHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
        };
        this.searchInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (
            searchOpen &&
            searchOpen !== prevState.searchOpen &&
            this.searchInput.current
        ) {
            this.searchInput.current.focus();
        }
    }

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    render() {
        const { openMobileMenu, rfq, compare } = this.props;
        const { searchOpen } = this.state;
        const searchClasses = classNames('mobile-header__search', {
            'mobile-header__search--open': searchOpen,
        });

        return (
            <div className="mobile-header">
                <div className="mobile-header__panel">
                    <div className="container">
                        <div className="mobile-header__body">
                            <button
                                type="button"
                                className="mobile-header__menu-button"
                                onClick={openMobileMenu}
                            >
                                <Menu18x14Svg />
                            </button>
                            <Link to="/" className="mobile-header__logo">
                                <LogoSmallSvg />
                            </Link>
                            <Search
                                context="mobile-header"
                                className={searchClasses}
                                inputRef={this.searchInput}
                                onClose={this.handleCloseSearch}
                            />
                            <div className="mobile-header__indicators">
                                <Indicator
                                    className="indicator--mobile indicator--mobile-search d-md-none"
                                    onClick={this.handleOpenSearch}
                                    icon={<Search20Svg />}
                                />
                                <Indicator
                                    className="indicator--mobile d-sm-flex d-none"
                                    url="/shop/rfq"
                                    value={rfq.length}
                                    icon={<RFQ20Svg />}
                                />
                                <Indicator
                                    className="indicator--mobile d-sm-flex d-none"
                                    url="/shop/compare"
                                    value={compare.length}
                                    icon={<Compare20Svg />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    rfq: state.rfq,
    compare: state.compare,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
