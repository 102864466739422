// react
import React from 'react';

const ProductTabDescription = (props) => {
    const { product } = props;
    return (
        <div className="typography">
            <p>{product.fullDescription}</p>
        </div>
    );
};

export default ProductTabDescription;
