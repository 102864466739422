export default [
    {
        title: 'Anodes',
        url: '/shop/catalog/anodes',
        products: 23,
        image: 'images/categories/category-1.jpg',
        subcategories: [
            { title: 'Magnesium', url: '/shop/catalog/anodes-mag' },
            { title: 'Aluminum', url: '/shop/catalog/anodes-alm' },
            { title: 'Zinc', url: '/shop/catalog/anodes-znc' },
            { title: 'Cast Iron', url: '/shop/catalog/anodes-cair' },
        ],
    },
    {
        title: 'Power Supplies',
        url: '/shop/catalog/power-supplies',
        products: 7,
        image: 'images/categories/category-2.jpg',
        subcategories: [
            { title: 'Rectifiers', url: '/shop/catalog/rectifiers' },
            { title: 'Junction Boxes', url: '/shop/catalog/junction-boxes' },
            { title: 'Amp Meter', url: '/shop/catalog/amp-meters' },
            { title: 'Multi Meter', url: '/shop/catalog/multi-meter' },
            { title: 'Wire/Cable', url: '/shop/catalog/wire-cable' },
        ],
    },
    {
        title: 'Test Equipment',
        url: '/shop/catalog/testing-equipment',
        products: 12,
        image: 'images/categories/category-4.jpg',
        subcategories: [
            {
                title: 'Reference Electrodes',
                url: '/shop/catalog/reference-electrodes',
            },
            { title: 'Test Poles', url: '/shop/catalog/test-pole' },
            { title: 'Anode Test Kit', url: '/shop/catalog/anode-test-kits' },
            { title: 'Test Stations', url: '/shop/catalog/test-station' },
            { title: 'Shunts', url: '/shop/catalog/shunts' },
        ],
    },
    {
        title: 'Connections & Splicing',
        url: '/shop/catalog/connections-splicing',
        products: 15,
        image: 'images/categories/category-3.jpg',
        subcategories: [
            { title: 'Splicing', url: '/shop/catalog/splicing' },
            { title: 'Exothermic', url: '/shop/catalog/exothermic' },
        ],
    },
];
