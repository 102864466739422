export default [
    {
        id: 1, 
        title: 'How Low-Cost Cathodic Protection Reduces Production Tank Leaks',
        body: '',
        image: 'images/posts/post-1.jpg',
        categories: ['Anodes'],
        date: 'April 7, 2021',
        pdf:'https://drive.google.com/uc?export=view&id=1nfOuwRcvown5aIsWypF-_N0tNJ1yBt0h'
    },
    {
        id: 2,
        title: 'Come visit us at the 2020 NACE International Corrosion Conference',
        body: 'Come see us at CORROSION 2020, Booth 129, the world’s largest conference and exposition on corrosion, welcomes more than 6,000 attendees hailing from over 70 countries. This comprehensive technical conference is packed with 1,000+ hours of technical education all focused on the study, prevention, and control of corrosion. Experience infinite opportunities to exchange knowledge and connect with the entire corrosion-fighting continuum: scientists, researchers, engineers, technicians, coatings inspectors and contractors, educators and students, as well as business executives and asset owners.',
        image: 'images/posts/post-2.jpg',
        link: 'http://nacecorrosion.org',
        categories: ['Expo'],
        date: 'March 15-19, 2020',
    },
    {
        id: 3,
        title: 'Come visit us at the 2020 NACE International Rocky Mountain Section',
        body: 'Come see us at the 40th Annual Rocky Mountain Short Course presented by the Rocky Mountain Section of NACE International.',
        image: 'images/posts/post-3.jpg',
        link:'https://www.nace.org/events/event-description?CalendarEventKey=b1fecf0f-098e-4905-8150-1071df4b6df9',
        categories: ['Expo'],
        date: 'January 23-24, 2020',
    },
    {
        id: 4,
        title: `Anode Systems and Anode Sales Company make $100,000 donation to Colorado Mesa University's Scholarship Program`,
        body: 'Anode Systems Co & Anode Sales Co President, Hans Schmoldt announced a donation of $100,000 to Colorado Mesa University. The aforementioned donation will go towards Scholarships to attend the local University. You can now find an honorary plaque in front of a lecture hall in the Houston Hall. Learn more below.',
        image: 'images/posts/post-4.jpg',
        link: 'https://www.coloradomesa.edu/now/2020/01/anode-systems-builds-mesa-county-scholarship-initiative.html',
        categories: ['Donations'],
        date: 'January 20, 2020',
    },
];