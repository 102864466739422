// react
import React from 'react';

// third-party
import { Link } from 'react-router-dom';

function WidgetTags() {
    return (
        <div className="widget-tags widget">
            <h4 className="widget__title">Tags Cloud</h4>
            <div className="tags tags--lg">
                <div className="tags__list">
                    <Link to="/shop/catalog/anodes">Anodes</Link>
                    <Link to="/shop/catalog/anodes-mag">Magnesium</Link>
                    <Link to="/shop/catalog/anodes-alm">Aluminum</Link>
                    <Link to="/shop/catalog/anodes-znc">Zinc</Link>
                    <Link to="/shop/catalog/anodes-cair">Cast Iron</Link>
                    <Link to="/shop/catalog/evaporative-cooler">Sunflower</Link>
                    <Link to="/shop/catalog/testing-equipment">Testing Equipment</Link>
                    <Link to="/shop/catalog/power-supplies">Power Supplies</Link>
                    <Link to="/shop/catalog/connections-splicing">Connection & Splicing</Link>
                </div>
            </div>
        </div>
    );
}

export default WidgetTags;
