// react
import React, { useState, useEffect } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
// application
import AsyncAction from '../shared/AsyncAction';
import PageHeader from '../shared/PageHeader';
import { Cross12Svg } from '../../svg';
import { url } from '../../services/utils';
import { rfqRemoveItem } from '../../store/rfq';

import * as emailjs from 'emailjs-com';

// data stubs
import theme from '../../data/theme';

const initialState = {
    name: '',
    email: '',
    message: '',
    products: [],
};

function ShopPageRFQ(props) {
    const { rfq, rfqRemoveItem } = props;
    const [values, setValues] = useState(initialState);

    const { name, email, message, products } = values;

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'RFQ', url: '' },
    ];

    let content;

    useEffect(() => {
        createProducts(rfq);
    }, [rfq]);

    const resetForm = () => {
        setValues(initialState);
    };

    const handleChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        
        const templateParams = {
            from_name: name,
            from_email: email,
            to_name: 'abel@anodesystems.com',
            message: message,
            message_html: products,
        };
        
        emailjs
            .send(
                'smtp_server',
                'template_rnzkpaIY',
                templateParams,
                'user_s752jBXbUEXqTwR6STCHF',
            )
            .then(
                (result) => {
                    toast.success('Message was successfully sent!');
                    resetForm();
                },
                (error) => {
                    toast.danger(error);
                },
            );
        
    };
    const createProducts = (rfq) => {
        const rfqProd = rfq.map((product) => product.name);
        setValues({ ...values, products: rfqProd });
    };

    if (rfq.length) {
        const itemsList = rfq.map((item) => {
            let image;

            if (item.images.length > 0) {
                image = (
                    <div className="product-image">
                        <Link
                            to={url.product(item)}
                            className="product-image__body"
                        >
                            <img
                                className="product-image__img"
                                src={item.images[0]}
                                alt=""
                            />
                        </Link>
                    </div>
                );
            }
            
            const renderRemoveButton = ({ run, loading }) => {
                const classes = classNames(
                    'btn btn-light btn-sm btn-svg-icon',
                    {
                        'btn-loading': loading,
                    },
                );

                return (
                    <button
                        type="button"
                        onClick={run}
                        className={classes}
                        aria-label="Remove"
                    >
                        <Cross12Svg />
                    </button>
                );
            };

            return (
                <tr key={item.id} className="rfq__row">
                    <td className="rfq__column rfq__column--image">{image}</td>
                    <td className="rfq__column rfq__column--product">
                        <Link
                            to={url.product(item)}
                            className="rfq__product-name"
                        >
                            {item.name}
                        </Link>
                    </td>
                    <td className="rfq__column rfq__column--stock">
                        <div className="badge badge-success">In Stock</div>
                    </td>
                    <td className="rfq__column rfq__column--remove">
                        <AsyncAction
                            action={() => rfqRemoveItem(item.id)}
                            render={renderRemoveButton}
                        />
                    </td>
                </tr>
            );
        });

        content = (
            <div className="block">
                <div className="container">
                    <table className="rfq">
                        <thead className="rfq__head">
                            <tr className="rfq__row">
                                <th className="rfq__column rfq__column--image">
                                    Image
                                </th>
                                <th className="rfq__column rfq__column--product">
                                    Product
                                </th>
                                <th className="rfq__column rfq__column--stock">
                                    Stock Status
                                </th>
                                <th
                                    className="rfq__column rfq__column--remove"
                                    aria-label="Remove"
                                />
                            </tr>
                        </thead>
                        <tbody className="rfq__body">{itemsList}</tbody>
                    </table>
                    <div className="row pt-5">
                        <div className="col-md-6 offset-md-3 ">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <h5>Your Name</h5>
                                    <input
                                        type="name"
                                        className="w-100 form-control"
                                        placeholder="Your Name"
                                        onChange={handleChange('name')}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <h5>Your Email</h5>
                                    <input
                                        type="email"
                                        className="w-100 form-control"
                                        placeholder="Your email"
                                        onChange={handleChange('email')}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <h5>Notes</h5>
                                    <textarea
                                        rows="4"
                                        className="w-100 form-control"
                                        placeholder="Please include any notes that are deemed necessary for an accurate quote including amount and the Zip code for which it will be delivered"
                                        onChange={handleChange('message')}
                                        required
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Send in RFQ
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">
                            Your Request For Quote is empty!
                        </div>
                        <div className="block-empty__actions">
                            <Link to="/shop" className="btn btn-primary btn-sm">
                                Continue
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`RFQ — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="RFQ" breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    rfq: state.rfq,
});

const mapDispatchToProps = {
    rfqRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageRFQ);
