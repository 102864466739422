export default [
    { image: 'images/logos/logo-1.png' },
    { image: 'images/logos/logo-2.png' },
    { image: 'images/logos/logo-3.png' },
    { image: 'images/logos/logo-4.png' },
    { image: 'images/logos/logo-5.png' },
    { image: 'images/logos/logo-6.png' },
    { image: 'images/logos/logo-7.png' },
    { image: 'images/logos/logo-8.png' },
    { image: 'images/logos/logo-9.png' },
    { image: 'images/logos/logo-10.png' },
    { image: 'images/logos/logo-11.png' },
    { image: 'images/logos/logo-12.png' },
    { image: 'images/logos/logo-13.png' },
    { image: 'images/logos/logo-14.png' },
];
