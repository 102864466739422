// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { compareRemoveItem } from '../../store/compare';
import { url } from '../../services/utils';

// data stubs
import theme from '../../data/theme';

function ShopPageCompare(props) {
    const { products, compareRemoveItem } = props;
    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Comparison', url: '' },
    ];

    let content;

    if (products.length) {
        const attributes = [];
        products.forEach((product) =>
            product.attributes.forEach((productAttribute) => {
                let attribute = attributes.find(
                    (x) => x.name === productAttribute.name,
                );

                if (!attribute) {
                    attribute = {
                        name: productAttribute.name,
                        values: {},
                    };
                    attributes.push(attribute);
                }

                attribute.values[product.id] = productAttribute.values
                    .map((x) => x.name)
                    .join(', ');
            }),
        );

        const productInfoRow = products.map((product) => {
            let image;

            if (product.images.length > 0) {
                image = (
                    <div className="compare-table__product-image product-image">
                        <div className="product-image__body">
                            <img
                                className="product-image__img"
                                src={product.images[0]}
                                alt=""
                            />
                        </div>
                    </div>
                );
            }

            return (
                <td key={product.id}>
                    <Link
                        to={url.product(product)}
                        className="compare-table__product-link"
                    >
                        {image}
                        <div className="compare-table__product-name">
                            {product.name}
                        </div>
                    </Link>
                </td>
            );
        });

        const availabilityRow = products.map((product) => {
            let badge;

            if (product.availability.slug === 'in-stock') {
                badge = (
                    <span className="compare-table__product-badge badge badge-success">
                        In Stock
                    </span>
                );
            } else if (product.availability.slug === 'not-in-stock') {
                badge = (
                    <span className="compare-table__product-badge badge badge-danger">
                        Out of Stock
                    </span>
                );
            } else {
                badge = (
                    <span className="compare-table__product-badge badge badge-info">
                        UNavailable Status
                    </span>
                );
            }

            return <td key={product.id}>{badge}</td>;
        });

        const attributeImage = products.map((attrLink, index) => {
            return (
                <td key={index}>
                    <img
                        className="w-75"
                        src={`images/specsheets/${attrLink.specLink}.png`}
                    />
                </td>
            );
        });

        const removeRow = products.map((product) => {
            const renderButton = ({ run, loading }) => {
                const classes = classNames('btn btn-secondary btn-sm', {
                    'btn-loading': loading,
                });

                return (
                    <button type="button" onClick={run} className={classes}>
                        Remove
                    </button>
                );
            };

            return (
                <td key={product.id}>
                    <AsyncAction
                        action={() => compareRemoveItem(product.id)}
                        render={renderButton}
                    />
                </td>
            );
        });

        content = (
            <div className="block">
                <div className="container">
                    <div className="table-responsive">
                        <table className="compare-table">
                            <tbody>
                                <tr>
                                    <th>Product</th>
                                    {productInfoRow}
                                </tr>
                                <tr>
                                    <th>Availability</th>
                                    {availabilityRow}
                                </tr>
                                <tr>
                                    <th>Specifications</th>
                                    {attributeImage}
                                </tr>
                                <tr>
                                    <th aria-label="Remove" />
                                    {removeRow}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">
                            You have not chosen any products to compare!
                        </div>
                        <div className="block-empty__actions">
                            <Link to="/shop" className="btn btn-primary btn-sm">
                                Continue
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Compare Products Page — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Comparison" breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    products: state.compare,
});

const mapDispatchToProps = {
    compareRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCompare);
