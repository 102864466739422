const brandsData = [
    {
        name: 'Anode Sales Company',
        slug: 'anode',
        url: '/',
        image: 'assets/images/logos/logo-1.png',
    },
    {
        name: 'Tinker and Rasor',
        slug: 'tinker',
        url: 'http://tinker-rasor.com',
        image: 'assets/images/logos/logo-2.png',
    },
    {
        name: 'Test Products Intl.',
        slug: 'testproduct',
        url: 'http://testproductsintl.com',
        image: 'assets/images/logos/logo-3.png',
    },
    {
        name: 'Universal Rectifier',
        slug: 'universal',
        url: 'http://www.universalrectifiers.com',
        image: 'assets/images/logos/logo-4.png',
    },
    {
        name: 'Fanshier Corrosion',
        slug: 'fanshier',
        url: '/',
        image: 'assets/images/logos/logo-5.png',
    },
    {
        name: 'ThermOweld',
        slug: 'thermo',
        url: 'https://www.hubbell.com/continentalindustries/en/thermoweld-home',
        image: 'assets/images/logos/logo-6.png',
    },
    {
        name: '3M International',
        slug: '3m',
        url: 'https://www.3m.com',
        image: 'assets/images/logos/logo-7.png',
    },
    {
        name: '3N International',
        slug: '3n',
        url: 'https://www.3ninc.com',
        image: 'assets/images/logos/logo-8.png',
    },
    {
        name: 'Mag Specialties',
        slug: 'mag',
        url: 'https://www.magspecialtiesinc.com',
        image: 'assets/images/logos/logo-9.png',
    },
    {
        name: 'Anotec',
        slug: 'anotec',
        url: 'https://anotec.com',
        image: 'assets/images/logos/logo-10.png',
    },
    {
        name: 'KrisTech',
        slug: 'kristech',
        url: 'https://www.kristechwire.com',
        image: 'assets/images/logos/logo-11.png',
    },
    {
        name: 'Cott',
        slug: 'cott',
        url: 'https://www.cottmfg.com',
        image: 'assets/images/logos/logo-12.png',
    },
    {
        name: 'Gerome Test Stations',
        slug: 'gerome',
        url: 'https://www.gerometestox.com',
        image: 'assets/images/logos/logo-13.png',
    },
    {
        name: 'Royston',
        slug: 'royston',
        url: 'http://www.roystonlab.com/',
        image: 'assets/images/logos/logo-14.png',
    },
];

export default brandsData;
