// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';

import * as emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

const initialState = {
    name: '',
    email: '',
    subject: '',
    message: '',
};

function SitePageContactUs() {
    const [values, setValues] = useState(initialState);

    const { name, email, subject, message } = values;

    const handleChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
    };

    const resetForm = () => {
        setValues(initialState);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const templateParams = {
            from_name: name,
            from_email: email,
            to_name: 'abel@anodesystems.com',
            subject,
            message_html: message,
        };
        emailjs
            .send(
                'smtp_server',
                'template_yz7s49m',
                templateParams,
                'user_s752jBXbUEXqTwR6STCHF',
            )
            .then(
                (result) => {
                    toast.success('Message was successfully sent!');
                },
                (error) => {
                    toast.warning('Message was not sent!');
                },
            );
        resetForm()
    };

    const breadcrumb = [
        { title: 'Home', url: '/' },
        { title: 'Contact Us', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contact Us — ${theme.name}`}</title>
            </Helmet>

            <BlockMap />

            <PageHeader header="Contact Us" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">
                                            Our Address
                                        </h4>

                                        <div className="contact-us__address">
                                            <p>
                                                124 22nd Court, Grand Junction,
                                                CO 81501
                                                <br />
                                                Email: info@anodesystems.com
                                                <br />
                                                Phone Number: +1 970 243 4149
                                            </p>

                                            <p>
                                                <strong>Opening Hours</strong>
                                                <br />
                                                Monday to Friday: 7am-5pm MST
                                            </p>

                                            <p>
                                                <strong>Comment</strong>
                                                <br />
                                                Feel free to send over a comment
                                                or concern.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <h4 className="contact-us__header card-title">
                                            Leave us a Message
                                        </h4>

                                        <form onSubmit={handleSubmit}>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name">
                                                        Your Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Your Name"
                                                        value={name}
                                                        onChange={handleChange(
                                                            'name',
                                                        )}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Email Address"
                                                        value={email}
                                                        onChange={handleChange(
                                                            'email',
                                                        )}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-subject">
                                                    Subject
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Subject"
                                                    value={subject}
                                                    onChange={handleChange(
                                                        'subject',
                                                    )}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-message">
                                                    Message
                                                </label>
                                                <textarea
                                                    id="form-message"
                                                    className="form-control"
                                                    rows="4"
                                                    value={message}
                                                    onChange={handleChange(
                                                        'message',
                                                    )}
                                                    required
                                                />
                                            </div>
                                            
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                                                    Send Message
                                                </button>
                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageContactUs;
