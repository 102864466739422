// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import { url } from '../../services/utils';

function Suggestions(props) {
    const { context, className, products } = props;
    const rootClasses = classNames(
        `suggestions suggestions--location--${context}`,
        className,
    );

    const list =
        products &&
        products.map((product) => (
            <li key={product.id} className="suggestions__item">
                {product.images && product.images.length > 0 && (
                    <div className="suggestions__item-image product-image">
                        <div className="product-image__body">
                            <img
                                className="product-image__img"
                                src={product.images[0]}
                                alt=""
                            />
                        </div>
                    </div>
                )}
                <div className="suggestions__item-info">
                    <Link
                        className="suggestions__item-name"
                        to={url.product(product)}
                    >
                        {product.name}
                    </Link>
                </div>
            </li>
        ));

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">{list}</ul>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
