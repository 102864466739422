export default [
    {
        title: 'Magnesium Anodes',
        url: '/shop/catalog/anodes-mag',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'Bagged',
                    url: '/shop/catalog/bagged-mag',
                    submenu: [
                        {
                            title: 'High Potential',
                            url: '/shop/products/anode-hp-mag',
                        },
                        {
                            title: 'Standard Potential',
                            url: '/shop/products/anode-h1-mag',
                        },
                    ],
                },
                {
                    title: 'Extruded',
                    url: '/shop/catalog/extruded-mag',
                    submenu: [
                        {
                            title: '2 x 24',
                            url: '/shop/products/224-extruded-mag-anode',
                        },
                        {
                            title: '2 x 44',
                            url: '/shop/products/244-extruded-mag-anode',
                        },
                    ],
                },
                {
                    title: 'Drive-In',
                    url: '/shop/products/drive-in-mag-anode'
                },
                {
                    title: 'Condenser',
                    url: '/shop/products/condenser-mag-anode',
                },
            ],
        },
    },
    {
        title: 'Aluminum Anodes',
        url: '/shop/catalog/anodes-alm',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'Extruded',
                    url: '/shop/catalog/extruded-alm',
                    submenu: [
                        {
                            title: '2 x 24',
                            url: '/shop/products/224-extruded-alm-anode',
                        },
                        {
                            title: 'Red/Blue Heads',
                            url: '/shop/products/redbluehead-alm-anode',
                        },
                        {
                            title: 'Hanging Anodes',
                            url: '/shop/products/hanging-alm-anode',
                        },
                        {
                            title: 'Flanged Anodes',
                            url: '/shop/products/blind-flanged-alm-anode',
                        },
                        {
                            title: 'Tank Bottom Anodes',
                            url: '/shop/products/tank-bottom-alm-anode',
                        },
                    ],
                },
                {
                    title: 'Condenser',
                    url: '/shop/products/condenser-alm-anode',
                },
                {
                    title: 'Bowling Ball',
                    url: '/shop/products/bowling-ball-alm-anode',
                },
            ],
        },
    },
    {
        title: 'Zinc Anodes',
        url: '/shop/catalog/anodes-znc',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'Bagged', url: '/shop/products/bagged-zinc-anode' },
                { title: 'Ribbon', url: '/shop/products/ribbon-zinc-anode' },
                {
                    title: 'Sunflower',
                    url: '/shop/catalog/evaporative-cooler',
                    submenu: [
                        {
                            title: 'The Original Sunflower Anode',
                            url: '/shop/products/sunflower-zinc-anode',
                        },
                        {
                            title: 'The Sunflower Anode II',
                            url: '/shop/products/sunflower-zinc-anode-II',
                        },
                        {
                            title: 'The Copper/Zinc Anode',
                            url: '/shop/products/copper-zinc-anode',
                        },
                    ],
                },
            ],
        },
    },
    {
        title: 'Cast Iron Anodes',
        url: '/shop/catalog/anodes-cair',
    },
    {
        title: 'Power Supplies',
        url: '/shop/catalog/power-supplies',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'Amp Meter', url: '/shop/catalog/amp-meters' },
                {
                    title: 'Rectifier',
                    url: '/shop/catalog/rectifiers',
                    submenu: [
                        { title: 'ES1', url: '/shop/products/es1-rectifier' },
                        { title: 'ES2', url: '/shop/products/es2-rectifier' },
                    ],
                },
                { title: 'Junction Box', url: '/shop/products/junction-box' },
                {
                    title: 'Wire/Cable',
                    url: '/shop/products/cathodic-protection-cable',
                },
            ],
        },
    },
    {
        title: 'Testing Equipment',
        url: '/shop/catalog/testing-equipment',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'Test Pole',
                    url: '',
                    submenu: [
                        { title: 'Ski Style', url: '' },
                        { title: 'Cane Style', url: '' },
                    ],
                },
                { title: 'Copper Sulfate Reference Electrodes', url: '' },
                { title: 'Permanent Reference Electrodes', url: '' },
                { title: 'Voltmeter', url: '' },
                { title: 'Shunts', url: '' },
                { title: 'Test Heads', url: '' },
                { title: 'Test Stations', url: '' },
            ],
        },
    },
    {
        title: 'Connections and splicing',
        url: '/shop/catalog/connections-splicing',
    },
    {
        title: 'Resources',
        url: '',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'Webinars / Classes',
                    url: 'https://anodesystems.com',
                    props: {
                        external: true,
                        target: '_blank',
                    },
                },
                {
                    title: 'Installations',
                    url: 'https://anodesystems.com',
                    props: {
                        external: true,
                        target: '_blank',
                    },
                },
                {
                    title: 'Consulting',
                    url: 'https://anodesystems.com',
                    props: {
                        external: true,
                        target: '_blank',
                    },
                },
                {
                    title: 'Mapping',
                    url: 'https://anodesystems.com',
                    props: {
                        external: true,
                        target: '_blank',
                    },
                },
                {
                    title: 'Learn More',
                    url: 'https://anodesystems.com',
                    props: {
                        external: true,
                        target: '_blank',
                    },
                },
            ],
        },
    },
];
