// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import ProductGallery from './ProductGallery';
import { compareAddItem } from '../../store/compare';
import { RFQ16Svg, Compare16Svg, Down16Svg } from '../../svg';
import { rfqAddItem } from '../../store/rfq';

import theme from '../../data/theme';

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
        };
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    render() {
        const { product, layout, rfqAddItem, compareAddItem } = this.props;
        const { quantity } = this.state;
        let prices;

        if (product.compareAtPrice) {
            prices = (
                <React.Fragment>
                    <span className="product__new-price">
                        <Currency value={product.price} />
                    </span>{' '}
                    <span className="product__old-price">
                        <Currency value={product.compareAtPrice} />
                    </span>
                </React.Fragment>
            );
        } else {
            prices = (
                <h4 className="pb-5 text-center">
                    {`CALL ${theme.contacts.phone} FOR PRICING INFORMATION`}
                </h4>
            );
        }

        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    <ProductGallery
                        product={product}
                        layout={layout}
                        title={product.name}
                        images={product.images}
                        description={product.shortDescription}
                    />
                    <div className="product__info">
                        <h1 className="product__name">{product.name}</h1>
                        <div className="product__rating">
                            <div className="product__rating-legend">
                                <p className="text-muted">
                                    {`By: ${
                                        product.brand.name
                                            ? product.brand.name
                                            : 'ADD A BRAND FOR THIS PRODUCT'
                                    }`}
                                </p>
                            </div>
                        </div>
                        <h3>Description</h3>
                        <p>{product.shortDescription}</p>
                    </div>

                    <div className="product__sidebar">
                        <div className="product__availability">
                            Availability:{' '}
                            <span className="text-success">
                                {product.availability.name}
                            </span>
                        </div>

                        <div className="product__prices">{prices}</div>
                        <div className="row">
                            <div className="col-12">
                                <form className="product__options">
                                    <div className="form-group product__option">
                                        <div className="product__actions">
                                            <div className="product__actions-item product__actions-item--addtocart w-100">
                                                <AsyncAction
                                                    action={() =>
                                                        rfqAddItem(
                                                            product,
                                                            [],
                                                            quantity,
                                                        )
                                                    }
                                                    render={({
                                                        run,
                                                        loading,
                                                    }) => (
                                                        <button
                                                            type="button"
                                                            onClick={run}
                                                            disabled={!quantity}
                                                            className={classNames(
                                                                'btn btn-primary btn-lg ml-sm-1 mb-lg-1 w-100',
                                                                {
                                                                    'btn-loading':
                                                                        loading,
                                                                },
                                                            )}
                                                        >
                                                            <div>
                                                                <span>
                                                                    <RFQ16Svg className="float-right" />
                                                                    Add to RFQ
                                                                </span>
                                                            </div>
                                                        </button>
                                                    )}
                                                />

                                                <AsyncAction
                                                    action={() =>
                                                        compareAddItem(
                                                            product,
                                                            [],
                                                            quantity,
                                                        )
                                                    }
                                                    render={({
                                                        run,
                                                        loading,
                                                    }) => (
                                                        <button
                                                            type="button"
                                                            onClick={run}
                                                            disabled={!quantity}
                                                            className={classNames(
                                                                'btn btn-primary btn-lg ml-md-1 ml-sm-1 mb-lg-1 w-100 mt-sm-4',
                                                                {
                                                                    'btn-loading':
                                                                        loading,
                                                                },
                                                            )}
                                                        >
                                                            <span>
                                                                <Compare16Svg className="float-right" />
                                                                Compare Product
                                                            </span>
                                                        </button>
                                                    )}
                                                />
                                                <div className="row">
                                                    {product.specSheetLink && (
                                                        <div className="col-sm-12 col-lg-6">
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    window.open(
                                                                        `https://drive.google.com/uc?id=${product.specSheetLink}`,
                                                                        '_blank',
                                                                    );
                                                                }}
                                                                disabled={
                                                                    !quantity
                                                                }
                                                                className="btn btn-primary btn-lg ml-sm-1 mb-lg-1 w-100 mt-sm-4"
                                                            >
                                                                <span>
                                                                    <Down16Svg className="float-right" />
                                                                    Spec Sheet
                                                                </span>
                                                            </button>
                                                        </div>
                                                    )}
                                                    {product.lineSheetLink && (
                                                        <div className="col-sm-12 col-lg-6">
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    window.open(
                                                                        `https://drive.google.com/uc?id=${product.lineSheetLink}`,
                                                                        '_blank',
                                                                    );
                                                                }}
                                                                disabled={
                                                                    !quantity
                                                                }
                                                                className="btn btn-primary btn-lg ml-sm-1 mb-lg-1 w-100 mt-sm-4"
                                                            >
                                                                <span>
                                                                    <Down16Svg className="float-right" />
                                                                    Line Sheet
                                                                </span>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="product__footer">
                        <div className="product__tags tags">
                            <div className="tags__list"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
    layout: 'columnar',
};

const mapDispatchToProps = {
    rfqAddItem,
    compareAddItem,
};

export default connect(() => ({}), mapDispatchToProps)(Product);
