// react
import React, { useMemo } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import shopApi from '../../api/shop';
import {
    useDeferredData,
    useProductColumns,
    useProductTabs,
} from '../../services/hooks';

// blocks
import BlockBrands from '../blocks/BlockBrands';
import BlockCategories from '../blocks/BlockCategories';
import BlockPosts from '../blocks/BlockPosts';
import BlockProducts from '../blocks/BlockProducts';
import BlockSlideShow from '../blocks/BlockSlideShow';

// data stubs
import categories from '../../data/shopBlockCategories';
import posts from '../../data/blogPosts';
import theme from '../../data/theme';

function Home() {
    function shuffleArray(array) {
        let i = array.length - 1;
        for (; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    /**
     * Bestsellers.
     */
    const bestsellers = useDeferredData(
        () => shopApi.getPopularProducts({ limit: 60 }),
        [],
    );
    
    
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Home — ${theme.name}`}</title>
            </Helmet>

            {useMemo(
                () => (
                    <BlockSlideShow withDepartments />
                ),
                [],
            )}

            {useMemo(
                () => (
                    <BlockProducts
                        title="Products"
                        layout="large-last"
                        featuredProduct={bestsellers.data[0]}
                        products={shuffleArray(bestsellers.data.slice(1, 7))}
                    />
                ),
                [bestsellers.data],
            )}

            {useMemo(
                () => (
                    <BlockCategories
                        title="Categories"
                        layout="classic"
                        categories={categories}
                    />
                ),
                [],
            )}
            {useMemo(
                () => (
                    <BlockPosts
                        title="Latest Posts"
                        layout="list-sm"
                        posts={posts}
                    />
                ),
                [],
            )}
            {useMemo(
                () => (
                    <BlockBrands />
                ),
                [],
            )}
        </React.Fragment>
    );
}

export default Home;
