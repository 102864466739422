import { toast } from 'react-toastify';
import { RFQ_ADD_ITEM, RFQ_REMOVE_ITEM } from './rfqActionTypes';

export function rfqAddItemSuccess(product) {
    toast.success(`Product "${product.name}" added to rfq!`);
    
    return {
        type: RFQ_ADD_ITEM,
        product,
    };
}

export function rfqRemoveItemSuccess(productId) {
    return {
        type: RFQ_REMOVE_ITEM,
        productId,
    };
}

export function rfqAddItem(product) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(rfqAddItemSuccess(product));
                resolve();
            }, 500);
        })
    );
}

export function rfqRemoveItem(productId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(rfqRemoveItemSuccess(productId));
                resolve();
            }, 500);
        })
    );
}
