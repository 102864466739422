// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

// data stubs
import theme from '../../data/theme';

export default function Footer() {
    const informationLinks = [
        { title: 'About Us', url: '/about' },
        { title: 'Products', url: '/shop' },
        { title: 'Privacy Policy', url: '/privacy-policy' },
        { title: 'Contact Us', url: '/contact-us' },
        { title: 'Terms of Use', url: '/terms' },
    ];

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-6 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-6 col-md-6 col-lg-4">
                            <FooterLinks
                                title="Information"
                                items={informationLinks}
                            />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright text-muted">
                        Copyright &copy; <span id="year"></span> Anode Sales Company, All rights
                        reserved
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}
