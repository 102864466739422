// react
import React from 'react';

// data stubs
import specification from '../../data/shopProductSpec';

const ProductTabSpecification = (props) => {
    const { product } = props;
    // const sections = product.map((section, index) => {
    //     const features = section.attributes.map((feature, index) => (
    //         <div key={index} className="spec__row">
    //             <div className="spec__name">{feature.name}</div>
    //             <div className="spec__value">{feature.value}</div>
    //         </div>
    //     ));

    //     return (
    //         <div key={index} className="spec__section">
    //             <h4 className="spec__section-title">{section.name}</h4>
    //             {features}
    //         </div>
    //     );
    // });

    return (
        <div className="spec">
            <h3 className="spec__header">Specification</h3>
            <div className="row text-center">
                <div className="col">
                    <img
                        className="w-100"
                        style={{ maxWidth: '400px' }}
                        src={`images/specsheets/${product.specLink}.png`}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductTabSpecification;
