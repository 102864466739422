export default [
    { title: 'Shop', url: '/shop/catalog' },
    { title: 'Blog', url: '/blog' },
    { title: 'About Us', url: '/about' },
    { title: 'Contact Us', url: '/contact-us' },
    {
        title: 'Resources',
        url: 'https://www.anodesystems.com',
        props: {
            external: true,
            target: '_blank',
        },
    },
];
