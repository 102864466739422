import { RFQ_ADD_ITEM, RFQ_REMOVE_ITEM } from './rfqActionTypes';

function addItem(state, product) {
    const itemIndex = state.findIndex((x) => x.id === product.id);

    if (itemIndex === -1) {
        return [
            ...state,
            JSON.parse(JSON.stringify(product)),
        ];
    }
    
    return state;
}

const initialState = [];

export default function rfqReducer(state = initialState, action) {
    switch (action.type) {
    case RFQ_ADD_ITEM:
        return addItem(state, action.product);

    case RFQ_REMOVE_ITEM:
        return state.filter((x) => x.id !== action.productId);

    default:
        return state;
    }
}
