import AbstractFilterBuilder from './abstract';
import {
    categoriesListData,
    categoriesTreeData,
    prepareCategory,
} from '../database/categories';

import productsData from '../database/products';
import { categoryHasProducts } from '../utils';

export default class CategoryFilterBuilder extends AbstractFilterBuilder {
    value = null;
    items = [];
    
    test(product) {    
        if (this.value === null) {
            return true;
        }
        
        return product.categories.reduce(
            (acc, category) => acc || category.slug === this.value,
            false,
        );

        // return true;
    }
    
    makeItems(products, value) {
        this.value = value || null;
        const category = categoriesListData.find((x) => x.slug === value);
        //const categoryHasProductsFn = (x) => categoryHasProducts(x, productsData);
        const categoryHasProductsFn = () => true;
        if (category) {
            this.items = [prepareCategory(category, 1)].map((x) => ({
                ...x,
                children: x.children.filter(categoryHasProductsFn),
            }));
        } else {
            this.items = categoriesTreeData
                .map((x) => prepareCategory(x))
                .filter(categoryHasProductsFn);
        }
    }
    
    calc() {
        
    }
    
    build() {
        return {
            type: 'category',
            slug: this.slug,
            name: this.name,
            items: this.items,
            value: this.value,
        };
    }
}
