import { makeIdGenerator } from '../utils';
import brandsData from './brands';
import tagsData from './tags';
import availabilityData from './availability';
import { categoriesListData, prepareCategory } from './categories';

const getId = makeIdGenerator();

const attributesDef = [];

const productsDef = [
    // HP BAGGED - BAGGED
    {
        slug: 'hp-bagged-mag-anode',
        name: 'High Potential Bagged Magnesium Anodes',
        shortDescription:
            'For low current applications to protect underground storage tanks, a Bagged Magnesium Anode is an option for your cathodic protection needs.',
        fullDescription:
            'For low current applications to protect underground storage tanks, a Bagged Magnesium Anode is an option for your cathodic protection needs. Bagged magnesium anodes are installed in the ground near the tank and grounded to the outside of the tank. The bag is made of cotton and specifically designed to prevent ripping. Inside the bag is a proprietary blend of backfill that ensures proper performance for the life of the anode. The anode is a cast high potential (HP) magnesium block that is sourced from hand selected foundries, and is third party tested. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Made in the U.S.A. with U.S. and imported parts and materials.',
        
        images: [
            'images/products/hp-bagged-mag-anode.jpg',
            'images/products/hp-bagged-mag-anode-1.jpg',
            'images/products/hp-bagged-mag-anode-2.jpg',
            'images/products/hp-bagged-mag-anode-3.jpg',
            'images/products/hp-bagged-mag-anode-4.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-mag', 'bagged-mag'],
        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Magnesium Anodes',
                slug: 'anodes-mag',
                url: '/shop/catalog/anodes-mag',
            },
            {
                name: 'Magnesium Bagged',
                slug: 'bagged-mag',
                url: '/shop/catalog/bagged-mag',
            },
        ],
        specLink: 'hp-specs',
        specSheetLink: '1RfNV6xZsQC6novZ4XeoIPkbL7t18tESy',
        lineSheetLink: '1_GEgtpB3ZRVcqu3KUiO3yHlioWgmkpIx',
    },

    // H1 BAGGED - BAGGED
    {
        slug: 'h1-bagged-mag-anode',
        name: 'Standard Potential Bagged Magnesium Anodes',
        shortDescription:
            'For low current applications to protect underground storage tanks, the High Potential Bagged Magnesium Anode is an excellent option for your cathodic protection needs.',
        fullDescription:
            'For low current applications to protect underground storage tanks, the High Potential Bagged Magnesium Anode is an excellent option for your cathodic protection needs. This 20lb, bagged high potential (HP) magnesium anode is installed in the ground near the tank and grounded to the outside of the tank. The bag is made of cotton and specifically designed to prevent ripping. Inside the bag is our proprietary blend of backfill that ensures proper performance for the life of the anode. The anode is a cast alloyed (H1) magnesium block that is sourced from hand selected foundries and is third party tested. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Made in the U.S.A. with U.S. and imported parts and materials.',
        // price: 80,
        images: [
            'images/products/h1-bagged-mag-anode.jpg',
            'images/products/h1-bagged-mag-anode-1.jpg',
            'images/products/h1-bagged-mag-anode-2.jpg',
            'images/products/h1-bagged-mag-anode-3.jpg',
            'images/products/h1-bagged-mag-anode-4.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-mag', 'bagged-mag'],
        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Magnesium Anodes',
                slug: 'anodes-mag',
                url: '/shop/catalog/anodes-mag',
            },
            {
                name: 'Magnesium Bagged',
                slug: 'bagged-mag',
                url: '/shop/catalog/bagged-mag',
            },
        ],
        specLink: 'h1-specs',
        specSheetLink: '1RfNV6xZsQC6novZ4XeoIPkbL7t18tESy',
        lineSheetLink: '1_GEgtpB3ZRVcqu3KUiO3yHlioWgmkpIx',
    },
    
    // TANK BOTTOM - TANK BOTTOM
    {
        slug: 'mag-tank-bottom',
        name: 'Magnesium Potable Water Tank Anode',
        specSheetLink: '1RfNV6xZsQC6novZ4XeoIPkbL7t18tESy',
        shortDescription:
            'For the protection of the submerged wetted surface of steel potable water tanks with galvanic magnesium anodes. To comply with the National Association of Corrosion Engineers’ Standard Recommended Practice RP0169-2004.',
        fullDescription:
            'For the protection of the submerged wetted surface of steel potable water tanks with galvanic magnesium anodes. To comply with the National Association of Corrosion Engineers’ Standard Recommended Practice RP0169-2004.',
        price: 80,
        images: [
            'images/products/50-tank-bottom.jpg',
            'images/products/50-tank-bottom-1.jpg',
            'images/products/50-tank-bottom-2.jpg',
            'images/products/50-tank-bottom-3.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-mag', 'tank-bottom-mag'],
        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Magnesium Anodes',
                slug: 'anodes-mag',
                url: '/shop/catalog/anodes-mag',
            },
        ],
        specLink: 'mag-specs',
        specSheetLink: '13mUXrR7Sxuu4wcnw5sP1VYW2hU8XY9Mo',
        lineSheetLink: '1Oneo-4OSKIYspW3y0q8mERGo1GcoDWbP',
    },

    // 2X24 MAG ANODE - EXTRUDED
    {
        slug: '224-extruded-mag-anode',
        name: '2x24 Extruded Magnesium Anode',
        specSheetLink: '1RfNV6xZsQC6novZ4XeoIPkbL7t18tESy',
        shortDescription:
            'For low current applications in large tanks, the 2x44 Magnesium Flanged Anode is an option for your cathodic protection needs.',
        fullDescription:
            'For low current applications in large tanks, the 2x44 Magnesium Flanged Anode is an option for your cathodic protection needs. This 2 inch by 44 inch, magnesium anode is fitted with a 2 inch NPT head #10 thread cap which allows for the anode to attached to a flange or hanging assembly. The anode is an magnesium stick that is sourced from hand selected foundries and is third party tested. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/224-extruded-mag-anode.jpg',
            'images/products/224-extruded-mag-anode-1.jpg',
            'images/products/124-alm-anode-2.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-mag', 'extruded-mag'],
        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Magnesium Anodes',
                slug: 'anodes-mag',
                url: '/shop/catalog/anodes-mag',
            },
            {
                name: 'Extruded',
                slug: 'extruded-mag',
                url: '/shop/catalog/extruded-mag',
            },
        ],
        specLink: 'mag-specs',
        specSheetLink: '18f49_DS6Uite_xBLwQXDneOfqUAGGzyO',
        lineSheetLink: '1ulJGfb9z8guwQKTgUlHgGaiOpdzg1fgq',
    },

    // 2X44 MAG ANODE - EXTRUDED
    {
        slug: '244-extruded-mag-anode',
        name: '2x44 Extruded Magnesium Anode',
        shortDescription:
            'For low current applications in large tanks, the 2x24 Magnesium Flanged Anode is an option for your cathodic protection needs.',
        fullDescription:
            'For low current applications in large tanks, the 2x24 Magnesium Flanged Anode is an option for your cathodic protection needs. This 2 inch by 24 inch, magnesium anode is fitted with a 2 inch NPT head #10 thread cap which allows for the anode to attached to a flange or hanging assembly. The anode is an magnesium stick that is sourced from hand selected foundries and is third party tested. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/244-extruded-mag-anode.jpg',
            'images/products/244-extruded-mag-anode-1.jpg',
            'images/products/124-alm-anode-2.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-mag', 'extruded-mag'],

        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Magnesium Anodes',
                slug: 'anodes-mag',
                url: '/shop/catalog/anodes-mag',
            },
            {
                name: 'Extruded',
                slug: 'extruded-mag',
                url: '/shop/catalog/extruded-mag',
            },
        ],
        specLink: 'mag-specs',
        specSheetLink: '1L45a0bEtjzZVBsNU6XUItR78Hd-D6EQa',
        lineSheetLink: '',
    },

    // CONDENSER ANODE - CONDENSER
    {
        slug: 'condenser-mag-anode',
        name: 'Condenser Magnesium Anode',
        shortDescription:
            'Magnesium condenser anodes are used in many different applications such as tank interiors, condenser water boxes, heat exchangers, etc.',
        fullDescription:
            'Magnesium condenser anodes are used in many different applications such as tank interiors, condenser water boxes, heat exchangers, etc. These anodes are produced in a number of shapes, weights and configurations to meet customer requirements.',
        price: 80,
        images: [
            'images/products/condenser-mag-anode.jpg',
            'images/products/condenser-mag-anode-1.jpg',
            'images/products/condenser-mag-anode-2.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-mag', 'condenser-mag'],

        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Magnesium Anodes',
                slug: 'anodes-mag',
                url: '/shop/catalog/anodes-mag',
            },
            {
                name: 'Condenser Anodes',
                slug: 'condenser-mag',
                url: '/shop/catalog/condenser-mag',
            },
        ],
        specLink: 'mag-specs',
        specSheetLink: '1j86ru-gbUiV0yaV6RdjgW60eFSmSPsXM',
        lineSheetLink: '1zZIMgrY94cLZoBNs9vYt9eL4f9o8QCO7',
    },
    

    // DRIVE-IN - DRIVE-IN
    {
        slug: 'drive-in-mag-anode',
        name: 'Drive In Magnesium Anode',
        shortDescription:
            'For low current applications, Magnesium Drive-In Anodes are an economically beneficial and easy to install solution for your cathodic protection needs.',
        fullDescription:
            'For low current applications, Magnesium Drive-In Anodes are an economically beneficial and easy to install solution for your cathodic protection needs. This anode is manufactured from extruded magnesium rods and is fitted with either a bare, PVC, HDPE, or plastic coated cap. The cap has been attached to the anode core with a #12 THHN but can be manufactured in different lengths if needed. The opposite end of the lead wire is soldered to a copper sleeve and hose clamp. A galvanized sheet metal contact screw pierces the pipe coating and provides connection to the structure. This connection will provide a positive, long-life contact. The wire connection at the anode is coated to prevent exposure of bare copper. The anode has either a slanted or pointed tip which allows one person to install the anode in minutes with just a hammer and screwdriver. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/drive-in-mag-anode.jpg',
            'images/products/drive-in-mag-anode-1.jpg',
            'images/products/drive-in-mag-anode-2.jpg',
            'images/products/drive-in-mag-anode-3.jpg',
            'images/products/drive-in-mag-anode-4.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-mag', 'drive-in-mag'],

        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Magnesium Anodes',
                slug: 'anodes-mag',
                url: '/shop/catalog/anodes-mag',
            },
            {
                name: 'Drive-In Anodes',
                slug: 'drive-in-mag',
                url: '/shop/catalog/drive-in-mag',
            },
        ],
        specLink: 'mag-specs',
        specSheetLink: '1UlHRGN9qWfnsTrOTJk9U-krEohYmqme0',
        lineSheetLink: '1liE3WfaoYgMhJ4cEgwyCaBqcNEGas0tU',
    },

    // ALUMINUM ANODES
    // 1.25 X 24 ANODE - EXTRUDED
    {
        slug: '124-extruded-alm-anode',
        name: '1.25x24 Extruded Aluminum Anode',
        shortDescription:
            'For low current applications in large tanks, the 1.25x24 Aluminum Flanged Anode is an option for your cathodic protection needs.',
        fullDescription:
            'For low current applications in large tanks, the 1.25x24 Aluminum Flanged Anode is an option for your cathodic protection needs. This 2 inch by 24 inch, aluminum anode is fitted with a 2 inch NPT head #10 thread cap which allows for the anode to attached to a flange or hanging assembly. The anode is an magnesium stick that is sourced from hand selected foundries and is third party tested. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/124-alm-anode.jpg',
            'images/products/124-alm-anode-1.jpg',
            'images/products/124-alm-anode-2.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: [
            'anodes',
            'anodes-alm',
            'extruded-alm',
            '124-extruded-alm-anode',
        ],

        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                url: '/shop/catalog/anodes-alm',
            },
            {
                name: 'Extruded Aluminum',
                slug: 'extruded-alm',
                url: '/shop/catalog/extruded-alm',
            },
        ],
        specLink: 'alm-specs',
        specSheetLink: '1Fi_et64KCbKVN0IXCewL1Y57evaW0Qmw',
        lineSheetLink: '',
    },

    // 2X24 ANODE - EXTRUDED
    {
        slug: '224-extruded-alm-anode',
        name: '2x24 Extruded Aluminum Anode',
        shortDescription:
            'For low current applications in large tanks, the 2x24 Aluminum Flanged Anode is an option for your cathodic protection needs.',
        fullDescription:
            'For low current applications in large tanks, the 2x24 Aluminum Flanged Anode is an option for your cathodic protection needs. This 2 inch by 24 inch, aluminum anode is fitted with a 2 inch NPT head #10 thread cap which allows for the anode to attached to a flange or hanging assembly. The anode is an magnesium stick that is sourced from hand selected foundries and is third party tested. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/224-alm-anode.jpg',
            'images/products/224-alm-anode-1.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-alm', 'extruded-alm'],

        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                url: '/shop/catalog/anodes-alm',
            },
            {
                name: 'Extruded Aluminum',
                slug: 'extruded-alm',
                url: '/shop/catalog/extruded-alm',
            },
        ],
        specLink: 'alm-specs',

        specSheetLink: '1Ex3uVncdJVgNjVKoLuwlCgo2s2v1he_4',
        lineSheetLink: '',
    },

    // RED AND BLUE HEAD HEATER TREATER ANODES - EXTRUDED
    {
        slug: 'redbluehead-alm-anode',
        name: 'Red and Blue Head Aluminum Anode',
        shortDescription:
            'A Red Head or Blue Head Anode are used to cathodically protect a tank with a Victaulic style tank nipple.',
        fullDescription:
            'A Red Head or Blue Head Anode are used to cathodically protect a tank with a Victaulic style tank nipple. The Red and Blue Head Anode are specified for 4" SCH 40 grooved Victaulic couplings and 4" SCH 80 grooved Victaulic couplings, respectively. The anode can be cut to any length from 10 inches to 60 inches and up to 3 inches in diameter. Available in custom lengths and diameter or with steel caps for superior strength upon request. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/redbluehead-alm-anode.jpg',
            'images/products/redbluehead-alm-anode-1.jpg',
            'images/products/redbluehead-alm-anode-2.jpg',
            'images/products/redbluehead-alm-anode-3.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-alm', 'extruded-alm'],
        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                url: '/shop/catalog/anodes-alm',
            },
            {
                name: 'Extruded Aluminum',
                slug: 'extruded-alm',
                url: '/shop/catalog/extruded-alm',
            },
        ],
        specLink: 'alm-specs',
        specSheetLink: '1Fi_et64KCbKVN0IXCewL1Y57evaW0Qmw',
        lineSheetLink: '1Ykhsw1Vj05UWt_RdXbTrB5ly-EQ9bgt6',
    },

    // HANGING - EXTRUDED
    {
        slug: 'hanging-alm-anode',
        name: 'Hanging Aluminum Anode',
        shortDescription:
            '4" 150# designed to suspend your anode. Victaulic or flanged options exist. Single, dual, and triple hanging anode assemblies available.',
        fullDescription:
            '4" 150# designed to suspend your anode. Victaulic or flanged options exist. Single, dual, and triple hanging anode assemblies available. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Available in two sizes, the Big Boy and normal size. Either can be cut to customer specified length. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/hanging-alm-anode.jpg',
            'images/products/hanging-alm-anode-1.jpg',
            'images/products/hanging-alm-anode-2.jpg',
            'images/products/hanging-alm-anode-4.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-alm', 'extruded-alm'],

        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                url: '/shop/catalog/anodes-alm',
            },
            {
                name: 'Extruded Aluminum',
                slug: 'extruded-alm',
                url: '/shop/catalog/extruded-alm',
            },
        ],
        specLink: 'alm-specs',

        specSheetLink: '1JfBNzwVWmBV-_CjSK92GGhODUDHBIZks',
        lineSheetLink: '',
    },

    // BLIND FLANGE - EXTRUDED
    {
        slug: 'blind-flanged-alm-anode',
        name: 'Blind Flanged Aluminum Anode',
        shortDescription:
            '4" 150# or 300# flanges and specialty sizes are available upon request. Isolation Kits are available.',
        fullDescription:
            '4" 150# or 300# flanges and specialty sizes are available upon request. Isolation Kits are available. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Available in two sizes, the Big Boy and normal size. Either can be cut to customer specified length. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/blind-flanged-alm-anode.jpg',
            'images/products/blind-flanged-alm-anode-1.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-alm', 'extruded-alm'],

        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                url: '/shop/catalog/anodes-alm',
            },
            {
                name: 'Extruded Aluminum',
                slug: 'extruded-alm',
                url: '/shop/catalog/extruded-alm',
            },
        ],
        specLink: 'alm-specs',
        specSheetLink: '1JyyJMU3JTbSPNlGs7tFv1ka09HSlqHtW',
        lineSheetLink: '',
    },

    // FAGG FLANGE - EXTRUDED
    {
        slug: 'fagg-head-alm-anode',
        name: 'FAGG Head Aluminum Anode',
        shortDescription:
            '4" 150# or 300# flanges and specialty sizes are available upon request. Isolation Kits are available.',
        fullDescription:
            '4" 150# or 300# flanges and specialty sizes are available upon request. Isolation Kits are available. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Available in two sizes, the Big Boy and normal size. Either can be cut to customer specified length. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/fagg-head-alm-anode.jpg',
            'images/products/fagg-head-alm-anode-1.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-alm', 'extruded-alm'],

        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                url: '/shop/catalog/anodes-alm',
            },
            {
                name: 'Extruded Aluminum',
                slug: 'extruded-alm',
                url: '/shop/catalog/extruded-alm',
            },
        ],
        specLink: 'alm-specs',
        specSheetLink: '1GiScP5wp7uWELPCTsvUg04CRdkM6DFX8',
        lineSheetLink: '',
    },

    // CONDENSER ANODE - CONDENSER
    {
        slug: 'condenser-alm-anode',
        name: 'Condenser Aluminum Anode',
        shortDescription:
            'Aluminum condenser anodes are used in many different applications such as tank interiors, condenser water boxes, heat exchangers, etc.',
        fullDescription:
            'Aluminum condenser anodes are used in many different applications such as tank interiors, condenser water boxes, heat exchangers, etc. These anodes are produced in a number of shapes, weights and configurations to meet customer requirements.',
        price: 80,
        images: [
            'images/products/condenser-alm-anode.jpg',
            'images/products/condenser-alm-anode-1.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-alm', 'condenser-alm'],

        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                url: '/shop/catalog/anodes-alm',
            },
            {
                name: 'Condenser Aluminum',
                slug: 'condenser-alm',
                url: '/shop/catalog/condenser-alm',
            },
        ],
        specLink: 'alm-specs',
        specSheetLink: '1j86ru-gbUiV0yaV6RdjgW60eFSmSPsXM',
        lineSheetLink: '1kcma_M7aUQABAK1wyEMGZbjcBNrhSHsi',
    },

    // BOWLING BALL - BOWLING BALL
    {
        slug: 'bowling-ball-alm-anode',
        name: 'Bowling Ball Aluminum Anode',
        shortDescription:
            'When your project needs a special alternative to the standard aluminum tank anodes, we also offer the bowling ball style anode.',
        fullDescription:
            'When your project needs a special alternative to the standard aluminum tank anodes, we also offer the bowling ball style anode.',
        price: 80,
        images: [
            'images/products/bowling-ball-alm-anode.jpg',
            'images/products/bowling-ball-alm-anode-1.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-alm', 'bowling-ball-alm'],

        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                url: '/shop/catalog/anodes-alm',
            },
            {
                name: 'Bowling Ball',
                slug: 'bowling-ball-alm',
                url: '/shop/catalog/bowling-ball-alm',
            },
        ],
        specLink: 'alm-specs',
        specSheetLink: '1hjzMQCTKLmF6QgSFlE3MifOsd7MeLvWB',
        lineSheetLink: '',
    },

    // TANK BOTTOM - TANK BOTTOM
    {
        slug: 'alm-tank-bottom',
        name: 'Aluminum Tank Bottom Anode',
        shortDescription:
            '4" 150# or 300# flanges and specialty sizes are available upon request.',
        fullDescription:
            '4" 150# or 300# flanges and specialty sizes are available upon request. Isolation Kits are available. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Available in two sizes, the Big Boy and normal size. Either can be cut to customer specified length. Made in the U.S.A. with U.S. and imported parts and materials.',
        brand: 'anode',
        images: ['images/products/tank-bottom-alm-anode.jpg'],
        availability: 'in-stock',
        categories: [
            'anodes',
            'anodes-alm',
            'tank-bottom-alm',
            'extruded-tank-bottom',
        ],
        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                url: '/shop/catalog/anodes-alm',
            },
            {
                name: 'Tank Bottom Aluminum',
                slug: 'tank-bottom-alm',
                url: '/shop/catalog/tank-bottom-alm',
            },
        ],
        specLink: 'alm-specs',
        specSheetLink: '1vpLtiW7FyAHf8x1E-qJLIReumPbZZFNR',
        lineSheetLink: '1reliCF921vXw-rgfIoPbZVfn2GCF419B',
    },

    // BLOCK - TANK BOTTOM
    {
        slug: 'alm-block-anode',
        name: 'Aluminum Block Anode',
        shortDescription:
            'For low current applications in large tanks, the Aluminum Block Anode is an option for your cathodic protection needs.',
        fullDescription:
            'For low current applications in large tanks, the Aluminum Block Anode is an option for your cathodic protection needs. This aluminum anode is installed through the man-way of a tank and sits on the bottom of a tank. The anode is then grounded with the grounding cable, which is routed through a stuffing box and attached to the tank. The overall design of this cathodic protection system offers maximum seam protection from a man-way or port and the tank itself. The anode is a cast aluminum block that is sourced from hand selected foundries and is third party tested. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. The anode can be ordered with a tray to prevent contact between the anode and tank. Made in the U.S.A. with U.S. and imported parts and materials.',
        brand: 'anode',
        images: [
            'images/products/alm-block-anode.jpg',
            'images/products/alm-block-anode-1.jpg',
        ],
        availability: 'in-stock',
        categories: [
            'anodes',
            'anodes-alm',
            'tank-bottom-alm',
            'block-tank-bottom',
        ],
        tags: [
            {
                name: 'Anodes',
                slug: 'anodes',
                url: '/shop/catalog/anodes',
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                url: '/shop/catalog/anodes-alm',
            },
            {
                name: 'Tank Aluminum',
                slug: 'extruded-alm',
                url: '/shop/catalog/extruded-alm',
            },
        ],
        specLink: 'alm-specs',
        specSheetLink: '1vpLtiW7FyAHf8x1E-qJLIReumPbZZFNR',
        lineSheetLink: '1reliCF921vXw-rgfIoPbZVfn2GCF419B',
    },

    // ZINC
    // BAGGED ZINC - BAGGED
    {
        slug: 'bagged-zinc-anode',
        name: 'Bagged Zinc Anodes',
        shortDescription:
            'For low current applications to protect underground storage tanks, Bagged Zinc Anodes are an option for your cathodic protection needs.',
        fullDescription:
            'For low current applications to protect underground storage tanks, Bagged Zinc Anodes are an option for your cathodic protection needs. This bagged zinc anode is installed in the ground near the tank and then grounded to the outside of the tank. The bag is made of cotton and specifically designed to prevent ripping. Inside the bag is a proprietary blend of backfill that ensures proper cathodic performance for the life of the anode. The anode is a cast zinc block and is sourced from hand selected foundries and third party tested. Each anode has a plated steel core with 10 feet of #12 TW insulated lead wire attached with solder. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/bagged-zinc-anode.jpg',
            'images/products/bagged-zinc-anode-1.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-znc', 'zinc-bagged'],

        tags: [
            { name: 'Anodes', slug: 'anodes', url: '/shop/catalog/anodes' },
            {
                name: 'Zinc Anodes',
                slug: 'anodes-znc',
                url: '/shop/catalog/anodes-znc',
            },
        ],
        specLink: 'znc-specs',
        specSheetLink: '1x-rOaSpuMvodiOL0dYw_-BDNRbIVxE4N',
        lineSheetLink: '',
    },

    // RIBBON ZINC ANODE
    {
        slug: 'ribbon-zinc-anode',
        name: 'Ribbon Zinc Anodes',
        shortDescription:
            'Used for cathodic protection on buried pipelines, for A.C. mitigation on pipelines, for sacrificial cathodic protection of secondary bottoms on above-ground storage tanks, for A.C. mitigation grounding mats and for other corrosion protection applications.',
        fullDescription:
            'Used for cathodic protection on buried pipelines, for A.C. mitigation on pipelines, for sacrificial cathodic protection of secondary bottoms on above-ground storage tanks, for A.C. mitigation grounding mats and for other corrosion protection applications. Our zinc ribbon anode comes in a variety of configurations for many applications.',
        price: 80,
        images: [
            'images/products/ribbon-zinc-anode.jpg',
            'images/products/ribbon-zinc-anode-1.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-znc', 'zinc-ribbon'],

        tags: [
            { name: 'Anodes', slug: 'anodes', url: '/shop/catalog/anodes' },
            {
                name: 'Zinc Anodes',
                slug: 'anodes-znc',
                url: '/shop/catalog/anodes-znc',
            },
        ],
        specLink: 'znc-specs',
        specSheetLink: '1x-rOaSpuMvodiOL0dYw_-BDNRbIVxE4N',
        lineSheetLink: '',
    },

    // SUNFLOWER ANODE
    {
        slug: 'sunflower-zinc-anode',
        name: 'The Original Sunflower Zinc Anode',
        shortDescription:
            'Warm, stagnant water and moist pads of swamp coolers are a breeding ground for bacteria, mold, and mildew. The trapped spores from these microorganisms become airborne and inhaled when the fan is turned on.',
        fullDescription:
            'Warm, stagnant water and moist pads of swamp coolers are a breeding ground for bacteria, mold, and mildew. The trapped spores from these microorganisms become airborne and inhaled when the fan is turned on. Large concentrations of these germs can overwhelm our immune system’s ability to kill the invading harmful microorganisms. They can trigger allergies, asthma attacks, headaches, nausea, dizziness, and other respiratory problems. One way to "treat" the water that lies stagnant in the pan is to install a ZINC ANODE. It will control the growth of bacteria and the corrosion in the pan and cooler parts. This prolongs the life of the pads and the pan. Bleach and tablets are a "temporary fix." Magnesium rod anodes will not prevent the "fishy smell" or control the microorganisms. Only the Zinc Anode can do both. Is there a Zinc Anode in your "swamp cooler" at home, at work, and at school?',
        price: 80,
        images: ['images/products/sunflower-zinc-anode.jpg'],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-znc', 'evaporative-cooler'],
        tags: [
            { name: 'Anodes', slug: 'anodes', url: '/shop/catalog/anodes' },
            {
                name: 'Zinc Anodes',
                slug: 'anodes-znc',
                url: '/shop/catalog/anodes-znc',
            },
            {
                name: 'Sunflower Anodes',
                slug: 'evaporative-cooler',
                url: '/shop/catalog/evaporative-cooler',
            },
        ],
        specLink: 'sunflower-specs',
        specSheetLink: '1x-rOaSpuMvodiOL0dYw_-BDNRbIVxE4N',
        lineSheetLink: '',
    },

    //SUNFLOWER ANODE II
    {
        slug: 'sunflower-zinc-anode-II',
        name: 'The Sunflower Zinc Anode',
        shortDescription:
            'Do you suffer from asthma, allergies, sneezing, or coughing? You could be breathing airborne spores from fungus, bacteria, mold or other aquatic microorganisms that live in many evaporative "swamp" coolers.',
        fullDescription:
            'Do you suffer from asthma, allergies, sneezing, or coughing? You could be breathing airborne spores from fungus, bacteria, mold or other aquatic microorganisms that live in many evaporative "swamp" coolers. The Sunflower Zinc Anode is a zinc anode that sits at the bottom of a cooler pan. The Sunflower Anode releases zinc oxides into the water system of the cooler which makes the air you breathe fresher and healthier by preventing aquatic microorganisms from proliferating in the water system of the cooler system. Also, this zinc anode protects your cooler from rust through cathodic protection. Preventing rust can extend the lifespan of the cooler and save the owner money. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/sunflower-zinc-anode-II.jpg',
            'images/products/sunflower-zinc-anode-II-1.jpg',
            'images/products/sunflower-zinc-anode-II-2.jpg',
            'images/products/sunflower-zinc-anode-II-3.jpg',
            'images/products/sunflower-zinc-anode-II-4.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-znc', 'evaporative-cooler'],

        tags: [
            { name: 'Anodes', slug: 'anodes', url: '/shop/catalog/anodes' },
            {
                name: 'Zinc Anodes',
                slug: 'anodes-znc',
                url: '/shop/catalog/anodes-znc',
            },
            {
                name: 'Sunflower Anodes',
                slug: 'evaporative-cooler',
                url: '/shop/catalog/evaporative-cooler',
            },
        ],
        specLink: 'sunflowerII-specs',
        specSheetLink: '1x-rOaSpuMvodiOL0dYw_-BDNRbIVxE4N',
        lineSheetLink: '',
    },

    // COPPER/ZINC ANODE
    {
        slug: 'copper-zinc-anode',
        name: 'The Copper Zinc Anode',
        shortDescription:
            'Do you suffer from asthma, allergies, sneezing, or coughing? You could be breathing airborne spores from fungus, bacteria, mold or other aquatic microorganisms that live in many evaporative "swamp" coolers.',
        fullDescription:
            'Do you suffer from asthma, allergies, sneezing, or coughing? You could be breathing airborne spores from fungus, bacteria, mold or other aquatic microorganisms that live in many evaporative "swamp" coolers. The Sunflower Zinc Anode is a zinc anode that sits at the bottom of a cooler pan. The Sunflower Anode releases zinc oxides into the water system of the cooler which makes the air you breathe fresher and healthier by preventing aquatic microorganisms from proliferating in the water system of the cooler system. Also, this zinc anode protects your cooler from rust through cathodic protection. Preventing rust can extend the lifespan of the cooler and save the owner money. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/copper-zinc-anode.jpg',
            'images/products/copper-zinc-anode-1.jpg',
            'images/products/copper-zinc-anode-2.jpg',
            'images/products/copper-zinc-anode-3.jpg',
            'images/products/copper-zinc-anode-4.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-znc', 'evaporative-cooler'],
        tags: [
            { name: 'Anodes', slug: 'anodes', url: '/shop/catalog/anodes' },
            {
                name: 'Zinc Anodes',
                slug: 'anodes-znc',
                url: '/shop/catalog/anodes-znc',
            },
            {
                name: 'Sunflower Anodes',
                slug: 'evaporative-cooler',
                url: '/shop/catalog/evaporative-cooler',
            },
        ],
        specLink: 'copper-zinc-specs',
        specSheetLink: '1x-rOaSpuMvodiOL0dYw_-BDNRbIVxE4N',
        lineSheetLink: '',
    },

    // 27# CAST IRON CANISTER ANODE
    {
        slug: '27-canister-cast-iron-anode',
        name: '27# Canister Cast-Iron Anode',
        shortDescription:
            'The Cast Iron Canister Anode is used in impressed current systems.',
        fullDescription:
            'The Cast Iron Canister Anode is used in impressed current systems. The anode is sourced through Anotec and is made of high-silicon iron which is casted using Anotec’s proprietary chill casting process which assures a solid stick anode that will outperform the competition. The material also meets ASTM A518 Grade 3 Chemistry standards. A galvanized steel case is used to package the anode and the proprietary backfill which ensures proper performance for the life of the anode. We use a special epoxy sealant to cover the anode cable connection, the anodes themselves have retention features inside the anode, to secure anchors and epoxy for the life of the anode. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/27-canister-cast-iron-anode.jpg',
            'images/products/27-canister-cast-iron-anode-1.jpg',
            'images/products/27-canister-cast-iron-anode-2.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-cair'],

        tags: [
            { name: 'Anodes', slug: 'anodes', url: '/shop/catalog/anodes' },
            {
                name: 'Cast Iron Anodes',
                slug: 'anodes-cair',
                url: '/shop/catalog/anodes-cair',
            },
        ],
        specLink: 'cair-specs',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // 44# CAST IRON CANISTER
    {
        slug: '44-canister-cast-iron-anode',
        name: '44# Canister Cast-Iron Anode',
        shortDescription:
            'The Cast Iron Canister Anode is used in impressed current systems.',
        fullDescription:
            'The Cast Iron Canister Anode is used in impressed current systems. The anode is sourced through Anotec and is made of high-silicon iron which is casted using Anotec’s proprietary chill casting process which assures a solid stick anode that will outperform the competition. The material also meets ASTM A518 Grade 3 Chemistry standards. A galvanized steel case is used to package the anode and the proprietary backfill which ensures proper performance for the life of the anode. We use a special epoxy sealant to cover the anode cable connection, the anodes themselves have retention features inside the anode, to secure anchors and epoxy for the life of the anode. Made in the U.S.A. with U.S. and imported parts and materials.',
        price: 80,
        images: [
            'images/products/44-canister-cast-iron-anode.jpg',
            'images/products/44-canister-cast-iron-anode-1.jpg',
            'images/products/44-canister-cast-iron-anode-2.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['anodes', 'anodes-cair'],
        tags: [{ name: 'Anodes', slug: 'anodes', url: '/shop/catalog/anodes' }],
        specLink: 'cair-specs',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // TEST EQUIPMENT
    {
        slug: 'anode-test-kit',
        name: 'Anode Test Kit',
        shortDescription:
            'Our Anode Test Kit represents an easy-to-use testing kit, allowing both “structure-to-soil” and “structure-to-structure” voltage readings to be taken on above-grade and under-ground storage tanks.',
        fullDescription:
            'Our Anode Test Kit represents an easy-to-use testing kit, allowing both “structure-to-soil” and “structure-to-structure” voltage readings to be taken on above-grade and under-ground storage tanks.',
        price: 80,
        images: ['images/products/anode-test-kit.jpg'],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['testing-equipment', 'anode-test-kits'],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
        ],
        specLink: '',

        specSheetLink: '1JoBNzw-YnwJzHQAmEKBrdm0FQgnMaLrW',
        lineSheetLink: '',
    },

    // SKI STYLE TEST POLE
    {
        slug: 'ski-style-test-pole',
        name: 'Ski Style Test',
        shortDescription:
            'Includes a military aluminum grade pole, copper sulfate electrode, and digital multimeter with 10ft of premium lead wire with alligator clips.',
        fullDescription:
            'Includes a military aluminum grade pole, copper sulfate electrode, and digital multimeter with 10ft of premium lead wire with alligator clips. Built in conductivity to the volt meter with a ergonomically friendly voltmeter mounting pedestal.',
        price: 80,
        images: ['images/products/ski-style-test-pole.jpg'],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['testing-equipment', 'test-pole', 'ski-style'],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
            {
                name: 'Testing Pole',
                slug: 'test-pole',
                url: '/shop/catalog/test-pole',
            },
        ],
        specLink: 'cair-specs',

        specSheetLink: '1_TyxPguRZO2ge8BbVq4juG95QL8NikqS',
        lineSheetLink: '',
    },

    // CANE STYLE TEST POLE
    {
        slug: 'cane-style-test-pole',
        name: 'Cane Style Test',
        shortDescription:
            'Includes a military grade aluminum cane, copper sulfate electrode, and digital multimeter with 10ft of premium lead wire with alligator clips.',
        fullDescription:
            'Includes a military grade aluminum cane, copper sulfate electrode, and digital multimeter with 10ft of premium lead wire with alligator clips. Built in conductivity to the volt meter with a ergonomically friendly voltmeter mounting pedestal.',
        price: 80,
        images: ['images/products/cane-style-test-pole.jpg'],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['testing-equipment', 'test-pole', 'cane-style'],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
            {
                name: 'Testing Pole',
                slug: 'test-pole',
                url: '/shop/catalog/test-pole',
            },
        ],
        specLink: '',
        specSheetLink: '1tA3crb1INSZ9NL1p16kC-vwkZntYTyve',
        lineSheetLink: '',
    },

    // ELECTRODE
    {
        slug: 'portable-copper-copper-sulfate-reference-electrode',
        name: 'Portable Copper-Copper/Sulfate Reference Electrode',
        shortDescription:
            'Tinker & Rasor offers a complete line of Copper - Copper Sulfate Half Cell Reference Electrodes.',
        fullDescription:
            'Tinker & Rasor offers a complete line of Copper - Copper Sulfate Half Cell Reference Electrodes. Made of the most DURABLE PLASTICS, the Half Cells will withstand severe abuse without damage. The pre-saturated ceramic plugs are of a special design for LOWER EARTH RESISTANCE contact and also allow for easy probing in hard earth. These ceramic plugs provide a larger surface area than ever before.',
        price: 80,
        images: [
            'images/products/portable-copper-copper-sulfate-reference-electrode.jpg',
        ],
        availability: 'in-stock',
        brand: 'tinker',
        categories: ['testing-equipment', 'reference-electrodes'],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
            {
                name: 'Reference Electrodes',
                slug: 'reference-electrodes',
                url: '/shop/catalog/reference-electrodes',
            },
        ],
        specLink: '',

        specSheetLink: '1AUq1STW115eswT1IKeBCZ_lOjAUmpyMF',
        lineSheetLink: '',
    },

    // ANTI FREEZE SOLUTIONS
    {
        slug: 'anti-freeze-solution',
        name: 'Anti Freeze Solution',
        shortDescription:
            'Anti-free solution is used in portable, copper-copper sulfate reference electrodes in cold weather locations to prevent freezing and breakage of the reference electrode.',
        fullDescription:
            'Anti-free solution is used in portable, copper-copper sulfate reference electrodes in cold weather locations to prevent freezing and breakage of the reference electrode.  Available in a 32 ouce bottle, this product replaces standard copper sulfate crystals mixed with water.',
        price: 80,
        images: ['images/products/anti-freeze-solution.jpg'],
        availability: 'in-stock',
        brand: 'tinker',
        categories: ['testing-equipment', 'reference-electrodes', 'misc'],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
            {
                name: 'Reference Electrodes',
                slug: 'reference-electrodes',
                url: '/shop/catalog/reference-electrodes',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // COPPER SULFATE
    {
        slug: 'copper-sulfate-crystals',
        name: 'Copper Sulfate Crystals',
        shortDescription:
            'Copper sulfate crystals are used to recharge portable, copper-copper sulfate reference electrodes used in the cathodic protection testing.',
        fullDescription:
            'Copper sulfate crystals are used to recharge portable, copper-copper sulfate reference electrodes used in the cathodic protection testing. Simply mix the copper sulfate crystals with distilled water to recharge the electrode.  At the same time, it is a good time to clean the internal copper rod with an abrasive product such as sand paper or a scouring pad but do not use emery cloth as this introduces metal onto the copper rod.',
        price: 80,
        images: ['images/products/copper-sulfate-crystals.jpg'],
        availability: 'in-stock',
        brand: 'tinker',
        categories: ['testing-equipment', 'reference-electrodes', 'misc'],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
            {
                name: 'Reference Electrodes',
                slug: 'reference-electrodes',
                url: '/shop/catalog/reference-electrodes',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // DIRECT BURIAL REFERENCE ANODE
    {
        slug: 'direct-burial-reference-electrode',
        name: 'Direct Burial Reference Electrode',
        shortDescription:
            'Tinker & Rasor offers a direct burial copper sulfate reference electrode. The Copper Sulfate Direct Burial Reference Electrodes features a proprietary, non-tip electrode.',
        fullDescription:
            'Tinker & Rasor offers a direct burial copper sulfate reference electrode. The Copper Sulfate Direct Burial Reference Electrodes features a proprietary, non-tip electrode, with direct exposure of copper sulfate mixture to backfill/soil and a large surface area of exposed copper sulfate mixture. This half cell has a life span of about 10 years.',
        price: 80,
        images: ['images/products/direct-burial-reference-electrode.jpg'],
        availability: 'in-stock',
        brand: 'tinker',
        categories: [
            'testing-equipment',
            'reference-electrodes',
            'direct-burial',
        ],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
            {
                name: 'Reference Electrodes',
                slug: 'reference-electrodes',
                url: '/shop/catalog/reference-electrodes',
            },
        ],
        specLink: '',

        specSheetLink: '1taEvgSk0TRlJkcU4h2Eoy8GLHv1F6BEX',
        lineSheetLink: '',
    },

    // TPI MULTIMETER
    {
        slug: 'tpi-digital-multimeter',
        name: 'TPI Digital Multimeter',
        shortDescription: `The 133 TPI Digital Multimeter is a hand-held manual ranging DMM.`,
        fullDescription: `The 133 TPI Digital Multimeter is a hand-held manual ranging DMM. It features extra large numerals on the LCD, and Data Hold for all functions and ranges. The 133 is an affordable choice offering measurements in all of the basic electrical functions. This DMM is a direct replacement for the one supplied on the Ski Style or Cane Style Test Poles.`,
        price: 80,
        images: ['images/products/tpi-digital-multimeter.jpg'],
        availability: 'in-stock',
        brand: 'testproduct',
        categories: ['testing-equipment', 'multi-meter'],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // SHUNTS
    {
        slug: 'shunts',
        name: 'Shunts',
        shortDescription:
            'A calibrated CottShunt® makes it possible to measure cathodic protection currents and potentials with the highest degree of accuracy, reliability, and ease.',
        fullDescription: `A calibrated CottShunt® makes it possible to measure cathodic protection currents and potentials with the highest degree of accuracy, reliability, and ease. Designed specifically for the cathodic protection industry and manufactured from the finest materials available by Cott Manufacturing Company in Los Angeles, California, the CottShunt® is available through Anode Sales Company`,
        price: 80,
        images: ['images/products/shunts.jpg'],
        availability: 'in-stock',
        brand: 'cott',
        categories: ['testing-equipment', 'shunts'],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // T3
    {
        slug: 't3-test-station',
        name: 'T3 Test Station',
        shortDescription:
            'Tinker & Rasor T-3 terminals are made from Lexan®, a clear polycarbonate injection molded plastic, allowing for easy viewing through the terminal to terminal points.',
        fullDescription:
            'Tinker & Rasor T-3 terminals are made from Lexan®, a clear polycarbonate injection molded plastic, allowing for easy viewing through the terminal to terminal points. This safety measure allows clear vision to the other side of the terminal so that your field personnel are not blindly reaching their hands into "hot" areas on the terminal. The Model T-3 can be custom configured to the specifications of the customer. Below are examples of custom work and accessories offered by Tinker & Rasor.',
        price: 80,
        images: ['images/products/t3-test-station.jpg'],
        availability: 'in-stock',
        brand: 'tinker',
        categories: ['testing-equipment', 'test-station'],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
            {
                name: 'Test Station',
                slug: 'test-station',
                url: '/shop/catalog/test-station',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // TESTOX
    {
        slug: 'testox-test-station',
        name: 'Testox Test Station',
        shortDescription:
            'Every product Gerome produces is made with durable materials, engineered to withstand the harshest environments while out in the field.',
        fullDescription:
            'Every product Gerome produces is made with durable materials, engineered to withstand the harshest environments while out in the field. Likewise, all terminals are fit to your needs and made from 1/4 - 20 nickel-plated brass.',
        price: 80,
        images: ['images/products/testox-test-station.jpg'],
        availability: 'in-stock',
        brand: 'gerome',
        categories: ['testing-equipment', 'test-station'],

        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
            {
                name: 'Test Station',
                slug: 'test-station',
                url: '/shop/catalog/test-station',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // FLUSH MOUNT
    {
        slug: 'flush-mount-test-station',
        name: 'Flush Mount Test Station',
        shortDescription:
            'The Flush FINK ® cathodic protection test station and terminal enclosure is a high strength, maintenance free, non conductive, flush mounted, below ground terminal.',
        fullDescription:
            'The Flush FINK ® cathodic protection test station and terminal enclosure is a high strength, maintenance free, non conductive, flush mounted, below ground terminal. Its patented Bell Jar design keeps test leads dry even when the enclosure is covered by flood water. Field proven since 1978, Flush FINK ® is made by Cott Manufacturing in Pittsburgh, Pennsylvania and Los Angeles, California, it is available for quick delivery from Anode Systems Company.',
        price: 80,
        images: [
            'images/products/flush-mount-test-station.jpg',
            'images/products/flush-mount-test-station-1.jpg',
            'images/products/flush-mount-test-station-2.jpg',
        ],
        availability: 'in-stock',
        brand: 'cott',
        categories: ['testing-equipment', 'test-station', 'flush-mount'],
        tags: [
            {
                name: 'Testing Equipment',
                slug: 'testing-equipment',
                url: '/shop/catalog/testing-equipment',
            },
            {
                name: 'Test Station',
                slug: 'test-station',
                url: '/shop/catalog/test-station',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // POWER SUPPLIES
    // 1 AMP PVC AMP METER
    {
        slug: '1-amp-pvc-amp-meter',
        name: '1 Amp PVC Amp Meter',
        shortDescription:
            'Measuring instrument used to measure the current up to 1 Amp with a PVC housing.',
        fullDescription:
            'Measuring instrument used to measure the current up to 1 Amp with a PVC housing.',
        price: 80,
        images: ['images/products/1-amp-pvc-amp-meter.jpg'],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['power-supplies', 'amp-meters'],
        tags: [
            {
                name: 'Power Supplies',
                slug: 'power-supplies',
                url: '/shop/catalog/power-supplies',
            },
            {
                name: 'Amp Meters',
                slug: 'amp-meters',
                url: '/shop/catalog/amp-meters',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // 3 AMP PVC AMP METER
    {
        slug: '3-amp-pvc-amp-meter',
        name: '3 Amp PVC Amp Meter',
        shortDescription:
            'Measuring instrument used to measure the current up to 3 Amp with a PVC housing.',
        fullDescription:
            'Measuring instrument used to measure the current up to 3 Amp with a PVC housing.',
        price: 80,
        images: [
            'images/products/3-amp-pvc-amp-meter.jpg',
            'images/products/3-amp-pvc-amp-meter-1.jpg',
            'images/products/3-amp-pvc-amp-meter-2.jpg',
            'images/products/3-amp-pvc-amp-meter-3.jpg',
            'images/products/3-amp-pvc-amp-meter-4.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['power-supplies', 'amp-meters'],
        tags: [
            {
                name: 'Power Supplies',
                slug: 'power-supplies',
                url: '/shop/catalog/power-supplies',
            },
            {
                name: 'Amp Meters',
                slug: 'amp-meters',
                url: '/shop/catalog/amp-meters',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // 3 GALVANIZED AMP METER
    {
        slug: '3-amp-glv-amp-meter',
        name: '3 Amp Galvanized Amp Meter',
        shortDescription:
            'Measuring instrument used to measure the current up to 3 Amp with a Galvanized housing.',
        fullDescription:
            'Measuring instrument used to measure the current up to 3 Amp with a Galvanized housing.',
        price: 80,
        images: [
            'images/products/3-amp-glv-amp-meter.jpg',
            'images/products/3-amp-glv-amp-meter-1.jpg',
            'images/products/3-amp-glv-amp-meter-2.jpg',
            'images/products/3-amp-glv-amp-meter-3.jpg',
            'images/products/3-amp-glv-amp-meter-4.jpg',
        ],
        availability: 'in-stock',
        brand: 'fanshier',
        categories: ['power-supplies', 'amp-meters'],
        tags: [
            {
                name: 'Power Supplies',
                slug: 'power-supplies',
                url: '/shop/catalog/power-supplies',
            },
            {
                name: 'Amp Meters',
                slug: 'amp-meters',
                url: '/shop/catalog/amp-meters',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // ES1
    {
        slug: 'es1-rectifier',
        name: 'ES1 Rectifier',
        shortDescription:
            'This rectifier is a direct replacement for the one used on impressed current cathodic protection systems designed and installed by Anode Systems Company.',
        fullDescription:
            'This rectifier is a direct replacement for the one used on impressed current cathodic protection systems designed and installed by Anode Systems Company. The Universal Rectifier Model ES-1 Rectifier is a 120V single phase input, has an 18-step voltage control, and a maximum rating of 200 watts at 10 amps. This rectifier uses only circuit breakers (no fuses) and features a unique opening cabinet for total access to components. The ES-1 only comes with a ammeter.',
        price: 80,
        images: ['images/products/es1-rectifier.jpg'],
        availability: 'in-stock',
        brand: 'universal',
        categories: ['power-supplies', 'rectifiers'],
        tags: [
            {
                name: 'Power Supplies',
                slug: 'power-supplies',
                url: '/shop/catalog/power-supplies',
            },
            {
                name: 'Rectifiers',
                slug: 'rectifiers',
                url: '/shop/catalog/rectifiers',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    //ES2
    {
        slug: 'es2-rectifier',
        name: 'ES2 Rectifier',
        shortDescription:
            'This rectifier is a direct replacement for the one used on impressed current cathodic protection systems designed and installed by Anode Systems Company.',
        fullDescription:
            'This rectifier is a direct replacement for the one used on impressed current cathodic protection systems designed and installed by Anode Systems Company. The Universal Rectifier Model ES-1 Rectifier is a 120V single phase input, has an 18-step voltage control, and a maximum rating of 200 watts at 10 amps. This rectifier uses only circuit breakers (no fuses) and features a unique opening cabinet for total access to components. The ES-1 only comes with a ammeter.',
        price: 80,
        images: [
            'images/products/es2-rectifier.jpg',
            'images/products/es2-rectifier-1.jpg',
            'images/products/es2-rectifier-2.jpg',
            'images/products/es2-rectifier-3.jpg',
            'images/products/es2-rectifier-4.jpg',
        ],
        availability: 'in-stock',
        brand: 'universal',
        categories: ['power-supplies', 'rectifiers'],
        tags: [
            {
                name: 'Power Supplies',
                slug: 'power-supplies',
                url: '/shop/catalog/power-supplies',
            },
            {
                name: 'Rectifiers',
                slug: 'rectifiers',
                url: '/shop/catalog/rectifiers',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // JUNCTION BOX
    {
        slug: 'junction-box',
        name: 'Junction Box',
        shortDescription:
            'Junction boxes provide a low-cost solution to consolidate multiple impressed current anode cables and a means for monitoring individual anode currents.',
        fullDescription:
            'Junction boxes provide a low-cost solution to consolidate multiple impressed current anode cables and a means for monitoring individual anode currents.',
        price: 80,
        images: [
            'images/products/junction-box.jpg',
            'images/products/junction-box-1.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['power-supplies', 'junction-boxes'],

        tags: [
            {
                name: 'Power Supplies',
                slug: 'power-supplies',
                url: '/shop/catalog/power-supplies',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // CABLE
    {
        slug: 'cathodic-protection-cable',
        name: 'Cathodic Protection Cable',
        shortDescription:
            'CP Cable is used as direct earth burial DC feeder cable in cathodic protection systems to protect against galvanic and electrolytic corrosion of metallic objects – such as storage tanks, pipelines, wells and ocean vessels – that are buried or submerged in water.',
        fullDescription:
            'CP Cable is used as direct earth burial DC feeder cable in cathodic protection systems to protect against galvanic and electrolytic corrosion of metallic objects – such as storage tanks, pipelines, wells and ocean vessels – that are buried or submerged in water. We offer cathodic protection cable in several different gauges (1.9mm to 350 mm), 11 color options, customized print legends, non-standard lengths, smaller minimum runs, and no cut or reel charges — with the industry’s best lead times.',
        price: 80,
        images: ['images/products/cathodic-protection-cable.jpg'],
        availability: 'in-stock',
        brand: 'kristech',
        categories: ['power-supplies', 'wire-cable'],
        tags: [
            {
                name: 'Power Supplies',
                slug: 'power-supplies',
                url: '/shop/catalog/power-supplies',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // CONNECTIONS AND SPLICING
    // CADWELD KIT
    {
        slug: 'cadweld-kit',
        name: 'Cadweld Kit',
        shortDescription:
            'Used in the process of welding copper to copper, copper to steel and copper to ductile iron to forge a solid molecular bond that does not loosen or corrode, creating a superior and reliable connection method for vertical pipe connections.',
        fullDescription:
            'Used in the process of welding copper to copper, copper to steel and copper to ductile iron to forge a solid molecular bond that does not loosen or corrode, creating a superior and reliable connection method for vertical pipe connections. Anode Systems offers a variety of ThermoWeld kits including M100, M101, M106, M142, M150.',
        price: 80,
        images: ['images/products/cadweld-kit.jpg'],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['connections-splicing', 'exothermic'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
            {
                name: 'Exothermic',
                slug: 'exothermic',
                url: '/shop/catalog/exothermic',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    //SHOTS
    {
        slug: 'thermoweld-shots-caps',
        name: 'ThermOweld Shots and Caps',
        shortDescription:
            'Weld metal packaged in moisture-resistant plastic cartridges (20 count) ensures metal is dry and ready to ignite.',
        fullDescription:
            'Weld metal packaged in moisture-resistant plastic cartridges (20 count) ensures metal is dry and ready to ignite.',
        price: 80,
        images: [
            'images/products/thermoweld-shots-caps.jpg',
        ],
        availability: 'in-stock',
        brand: 'thermo',
        categories: ['connections-splicing', 'exothermic'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
            {
                name: 'Exothermic',
                slug: 'exothermic',
                url: '/shop/catalog/exothermic',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // SLEEVES
    {
        slug: 'thermoweld-copper-sleeves',
        name: 'ThermOweld Copper Sleeves',
        shortDescription:
            'The copper adapter sleeve slides onto the exposed conductor of the cable you are looking at completing an exothermic connection with.',
        fullDescription:
            'The copper adapter sleeve slides onto the exposed conductor of the cable you are looking at completing an exothermic connection with. The sleeve helps the copper wire fit properly with the mold to allow the exothermic process to work at its optimal performance.',
        price: 80,
        images: [
            'images/products/thermoweld-copper-sleeves.jpg',
            
        ],
        availability: 'in-stock',
        brand: 'thermo',
        categories: ['connections-splicing', 'exothermic'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
            {
                name: 'Exothermic',
                slug: 'exothermic',
                url: '/shop/catalog/exothermic',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // HANDICAPS
    {
        slug: 'thermoweld-handicaps',
        name: 'ThermOweld Handycaps',
        shortDescription:
            'The copper adapter sleeve slides onto the exposed conductor of the cable you are looking at completing an exothermic connection with.',
        fullDescription:
            'The copper adapter sleeve slides onto the exposed conductor of the cable you are looking at completing an exothermic connection with. The sleeve helps the copper wire fit properly with the mold to allow the exothermic process to work at its optimal performance.',
        price: 80,
        images: ['images/products/thermoweld-handicaps.jpg'],
        availability: 'in-stock',
        brand: 'thermo',
        categories: ['connections-splicing', 'exothermic'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
            {
                name: 'Exothermic',
                slug: 'exothermic',
                url: '/shop/catalog/exothermic',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // COPPER LUGS
    {
        slug: 'copper-lugs',
        name: 'Copper Lugs',
        shortDescription:
            'Pure copper cable lugs are heavy duty, pure copper, UL Listed electrical connectors designed for high amperage applications.',
        fullDescription:
            'Type L - Copper Single Conductor, One-Hole Mount, Socket, Screwdriver Slot Head Screws, Conductor Range 14 Sol-4 Str, Length 1-1/8 Inches, Width 17/32 Inch, Height 35/64 Inch, Pure copper cable lugs are heavy duty, pure copper, UL Listed electrical connectors designed for high amperage applications. ',
        price: 80,
        images: [
            'images/products/copper-lugs.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['connections-splicing', 'exothermic'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // CRIMPIT
    {
        slug: 'crimpit',
        name: 'Crimpit',
        shortDescription:
            'Type YC-C copper crimpit connector is a range-taking "C" shape compression connector for making tap or parallel copper connection from #12 solid to 4/0 stranded conductors.',
        fullDescription:
            'Type YC-C copper crimpit connector is a range-taking "C" shape compression connector for making tap or parallel copper connection from #12 solid to 4/0 stranded conductors. ',
        price: 80,
        images: [
            'images/products/crimpit.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['connections-splicing', 'splicing'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
            {
                name: 'Splicing',
                slug: 'splicing',
                url: '/shop/catalog/splicing',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // ISOLATION KIT
    {
        slug: 'isolation-kit',
        name: 'Isolation Kit',
        shortDescription:
            'The purpose of flange isolation kits is to prevent the (electrolytic) corrosion of flanges and flanged joints that static currents present on a piping system may induce.',
        fullDescription:
            'The purpose of flange isolation kits is to prevent the (electrolytic) corrosion of flanges and flanged joints that static currents present on a piping system may induce. The gasket isolation kit consists of a special gasket, isolation sleeves for all the stud bolts and nuts, and special washers. These components are manufactured in special materials with proper chemical stability, low water absorption, and dielectric properties.',
        price: 80,
        images: [
            'images/products/isolation-kit.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['connections-splicing'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    //MOLDS
    {
        slug: 'thermoweld-exothermic-molds',
        name: 'ThermOweld Exothermic Molds',
        shortDescription:
            'Cadweld connections are the accepted method of attaching Cathodic Protection leads to pipes (steel or cast iron), tanks and structures.',
        fullDescription:
            'Cadweld connections are the accepted method of attaching Cathodic Protection leads to pipes (steel or cast iron), tanks and structures. Cadweld connections weld the conductors and the structure to be protected so no galvanic corrosion can occur at the interface. The Cadweld process is specifically formulated to provide minimum heat effect on steel, which is especially important on thin-wall, high-stress pipes. Cadweld connections are also used for header cable taps, conductor splices and terminations, and ground rod connections',
        price: 80,
        images: [
            'images/products/thermoweld-exothermic-molds.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['connections-splicing', 'exothermic'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
            {
                name: 'Exothermic',
                slug: 'exothermic',
                url: '/shop/catalog/exothermic',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // DIELECTRIC UNION
    {
        slug: 'dielectric-unions',
        name: 'Dielectric Unions',
        shortDescription:
            'Dielectric Unions are used in commercial and residential applications to prevent accelerated corrosion and deterioration in the piping system due to galvanic and stray current.',
        fullDescription:
            'Dielectric Unions are used in commercial and residential applications to prevent accelerated corrosion and deterioration in the piping system due to galvanic and stray current. It is installed between pipes made from dissimilar metal. We offer dielectric unions in a range of configurations, sizes, and materials.',
        price: 80,
        images: [
            'images/products/dielectric-unions.jpg',
        ],

        availability: 'in-stock',
        brand: 'anode',
        categories: ['connections-splicing'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // GROUNDING CLAMP 24 & 40
    {
        slug: 'grounding-clamps',
        name: 'Grounding Clamps',
        shortDescription:
            'Using a hose clamp we connect a copper sleeve, this allows you to connect the anode to a pipe with a solid connection',
        fullDescription:
            'Using a hose clamp we connect a copper sleeve, this allows you to connect the anode to a pipe with a solid connection',
        price: 80,
        images: [
            'images/products/grounding-clamps.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['connections-splicing'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // STUFFING BOX
    {
        slug: 'stuffing-box',
        name: 'Stuffing Box',
        shortDescription:
            'Stuffing Boxes are used with the Blind Flange Hanger assembly to create a seal around the cable.',
        fullDescription:
            'Stuffing Boxes are used with the Blind Flange Hanger assembly to create a seal around the cable.',
        price: 80,
        images: [
            'images/products/stuffing-box.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['connections-splicing'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // FLINT IGNITOR GUN
    {
        slug: 'flint-ignitor',
        name: 'Flint Ignitor',
        shortDescription: 'Flint Ignitor.',
        fullDescription:
            'Flint Ignitor. Used to Ignite the Starting Material when making a Cadweld Connection.',
        price: 80,
        images: [
            'images/products/flint-ignitor.jpg',
        ],
        availability: 'in-stock',
        brand: 'anode',
        categories: ['connections-splicing', 'exothermic'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
            {
                name: 'Exothermic',
                slug: 'exothermic',
                url: '/shop/catalog/exothermic',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // VARIOUS PREMIUM ELECTRICAL TAPE
    {
        slug: '3m-electrical-tape',
        name: '3M Premium Electrical Tape',
        shortDescription:
            'Rubber splicing tape 130C is used as a primary electrical insulation for splicing all solid dielectric cables and for building stress cones on cables.',
        fullDescription:
            'Rubber splicing tape 130C is used as a primary electrical insulation for splicing all solid dielectric cables and for building stress cones on cables. Durable splicing/terminating tape stands up to the elements and seals out moisture. This premium-grade rubber splicing tape is 30-mil thick and handles temperatures up to 194°F (90°C).',
        price: 80,
        images: [
            'images/products/3m-electrical-tape.jpg',
        ],
        availability: 'in-stock',
        brand: '3m',
        categories: ['connections-splicing', 'splicing'],
        tags: [
            {
                name: 'Connections Splicing',
                slug: 'connections-splicing',
                url: '/shop/catalog/connections-splicing',
            },
            {
                name: 'Splicing',
                slug: 'splicing',
                url: '/shop/catalog/splicing',
            },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // ROYSTON ROYBOND
    {
        slug: 'royston-roybond-747',
        name: 'Royston Roybond 747',
        shortDescription:
            'Royston Roybond 747 is an elastomer-resin based formulation used as a corrosion resistant adhesive for adhering Royston Greenline Accessory Wrap to both metallic and non-metallic surfaces for underground service.',
        fullDescription:
            'Royston Roybond 747 is an elastomer-resin based formulation used as a corrosion resistant adhesive for adhering Royston Greenline Accessory Wrap to both metallic and non-metallic surfaces for underground service.',
        price: 80,
        images: [
            'images/products/royston-roybond-747.jpg',
        ],
        availability: 'in-stock',
        brand: 'royston',
        categories: ['connections-splicing', 'splicing'],

        tags: [
            { slug: 'splicing' },
            { slug: 'connection' },
            { slug: 'royston' },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // ROYSTON MASTIC
    {
        slug: 'royston-mastic',
        name: 'Royston Mastic',
        shortDescription: 'Totally resistant to aliphatic hydrocarbons.',
        fullDescription:
            'Totally resistant to aliphatic hydrocarbons. Easiest to apply (even in severe weather). Multi-application versatility for any oil/gas line surface. Finest engineered thixotropic viscosity.',
        price: 80,
        images: [
            'images/products/royston-mastic.jpg',
        ],
        availability: 'in-stock',
        brand: 'royston',
        categories: ['connections-splicing', 'splicing'],

        tags: [
            { slug: 'splicing' },
            { slug: 'connection' },
            { slug: 'royston' },
        ],
        specLink: '',
        specSheetLink: '',
        lineSheetLink: '',
    },

    // TODO: ADD ASSEMBLIES AND COKE BREEZE
];

const productsData = productsDef.map((productDef) => {
    let badges = [];

    if (productDef.badges) {
        badges =
            typeof productDef.badges === 'string'
                ? [productDef.badges]
                : productDef.badges;
    }

    const categories = categoriesListData
        .filter((category) => productDef.categories.includes(category.slug))
        .map((category) => prepareCategory(category));

    const attributes = (productDef.attributes || [])
        .map((productAttributeDef) => {
            const attributeDef = attributesDef.find(
                (x) => x.slug === productAttributeDef.slug,
            );

            if (!attributeDef) {
                return null;
            }

            let valuesDef = [];

            if (typeof productAttributeDef.values === 'string') {
                valuesDef = [productAttributeDef.values];
            } else if (productAttributeDef.values) {
                valuesDef = productAttributeDef.values;
            }

            const values = valuesDef
                .map((valueSlug) => {
                    const valueDef = attributeDef.values.find(
                        (x) => x.slug === valueSlug,
                    );

                    if (!valueDef) {
                        return null;
                    }

                    return {
                        ...valueDef,
                        customFields: {},
                    };
                })
                .filter((x) => x !== null);

            if (!values.length) {
                return null;
            }

            return {
                name: attributeDef.name,
                slug: attributeDef.slug,
                featured: !!productAttributeDef.featured,
                values,
                customFields: {},
            };
        })
        .filter((x) => x !== null);
    return {
        id: getId(),
        name: productDef.name,
        shortDescription: productDef.shortDescription,
        fullDescription: productDef.fullDescription,
        sku: '83690/32',
        slug: productDef.slug,
        price: productDef.price,
        compareAtPrice: productDef.compareAtPrice || null,
        images: productDef.images.slice(),
        badges: badges.slice(),
        availability:
            availabilityData.find((x) => x.slug === productDef.availability) ||
            null,
        brand: brandsData.find((x) => x.slug === productDef.brand) || null,
        categories,
        attributes,
        customFields: {},
        tags: productDef.tags,
        specLink: productDef.specLink,
        specSheetLink: productDef.specSheetLink,
        lineSheetLink: productDef.lineSheetLink,
    };
});

export default productsData;
