export default [
    {
        id: 1,
        name: 'Anodes',
        url: '',
        children: [
            { id: 2, name: 'Magnesium', url: '' },
            { id: 3, name: 'Aluminum', url: '' },
            { id: 4, name: 'Zinc', url: '' },
            { id: 5, name: 'Cast Iron', url: '' },
            
        ],
    },
    {
        id: 6,
        name: 'Power Supplies',
        url: '',
        children: [
            { id: 7, name: 'Amp Meter', url: '' },
            { id: 8, name: 'Rectifier', url: '' },
            { id: 9, name: 'Wire/Cable', url: '' },
            
        ],
    },
    {
        id: 10,
        name: 'Test Equipment',
        url: '',
        children: [
            { id: 11, name: 'Anode Test Kit', url: '' },
            { id: 12, name: 'Test Pole', url: '' },
            { id: 13, name: 'Reference Electrodes', url: '' },
            { id: 14, name: 'Multimeter', url: '' },
            { id: 15, name: 'Shunts', url: '' },
            { id: 16, name: 'Test Stations', url: '' },
        ],
    },
    {
        id: 17,
        name: 'Connections & Splicing',
        url: '',
        children: [
            { id: 18, name: 'CadWeld Kit', url: '' },
            { id: 19, name: 'Shots & Caps', url: '' },
            { id: 20, name: 'Copper Sleeves', url: '' },
            { id: 21, name: 'Copper Lugs', url: '' },
            { id: 22, name: 'Splicing Crimpits', url: '' },
            { id: 23, name: 'Isolation Kit', url: '' },
            { id: 24, name: 'ThermOweld Molds', url: '' },
            { id: 25, name: 'Dielectric Unions', url: '' },
            { id: 26, name: 'Grounding Clamps', url: '' },
            { id: 27, name: 'Electrical Tape', url: '' },
        ],
    },
];
