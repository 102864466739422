export default [
    {
        title: '$ US Dollar',
        currency: {
            code: 'USD',
            symbol: '$',
            name: 'US Dollar',
        },
    },
    
];
