export default [
    {
        type: 'link',
        label: 'Home',
        url: '/',
    },

    {
        type: 'link',
        label: 'Shop',
        url: '/shop/catalog',
        children: [
            {
                type: 'link',
                label: 'Anodes',
                url: '/shop/catalog/anodes',
            },
            {
                type: 'link',
                label: 'Power Supplies',
                url: '/shop/catalog/power-supplies',
            },
            {
                type: 'link',
                label: 'Testing Equipment',
                url: '/shop/catalog/testing-equipment',
            },
            {
                type: 'link',
                label: 'Connections & Splicing',
                url: '/shop/catalog/connections-splicing',
            },
        ],
    },
    {
        type: 'link',
        label: 'Resources',
        url: 'https://anodesystems.com',
    },
    { type: 'link', label: 'Blog', url: '/blog' },
    { type: 'link', label: 'About', url: '/about' },
    { type: 'link', label: 'Contact Us', url: '/contact-us' },
    { type: 'link', label: 'My RFQ', url: '/shop/rfq' },
    { type: 'link', label: 'My Compare', url: '/shop/compare' },
];
