import { makeIdGenerator } from '../utils';

const getId = makeIdGenerator();

const categoriesDef = [
    {
        name: 'Anodes',
        slug: 'anodes',
        items: 23,
        children: [
            {
                name: 'Magnesium',
                slug: 'anodes-mag',
                items: 28,
                children: [
                    {
                        name: 'Bagged/Packaged',
                        slug: 'bagged-mag',
                        items: 17,
                    },
                    {
                        name: 'Drive In',
                        slug: 'drive-in-mag',
                        items: 3,
                    },
                    {
                        name: 'Ribbon',
                        slug: 'ribbon-mag',
                        items: 1,
                    },
                    {
                        name: 'Extruded',
                        slug: 'extruded-mag',
                        items: 2,
                    },
                    {
                        name: 'Tank Bottom',
                        slug: 'tank-bottom-mag',
                        items: 1,
                    },
                    {
                        name: 'Condenser',
                        slug: 'condenser-mag',
                        items: 3,
                    },
                ],
            },
            {
                name: 'Aluminum',
                slug: 'anodes-alm',
                items: 10,
                children: [
                    {
                        name: 'Extruded',
                        slug: 'extruded-alm',
                        items: 7,
                    },
                    {
                        name: 'Bowling Ball',
                        slug: 'bowling-ball-alm',
                        items: 1,
                    },
                    {
                        name: 'Tank Bottom',
                        slug: 'tank-bottom-alm',
                        items: 2,
                    },
                ],
            },
            {
                name: 'Cast Iron',
                slug: 'anodes-cair',
                items: 2,
                children: [
                    {
                        name: 'Canister Anode',
                        slug: 'canister-anode',
                        items: 2,
                    },
                ],
            },
            {
                name: 'Zinc',
                slug: 'anodes-znc',
                items: 10,
                children: [
                    {
                        name: 'Bagged',
                        slug: 'bagged-zinc',
                        items: 6,
                    },
                    {
                        name: 'Ribbon',
                        slug: 'zinc-ribbon',
                        items: 1,
                    },

                    {
                        name: 'Evaporative Cooler',
                        slug: 'evaporative-cooler',
                        items: 3,
                    },
                ],
            },
        ],
    },
    {
        name: 'Power Supplies',
        slug: 'power-supplies',
        items: 8,
        children: [
            {
                name: 'Amp Meters',
                slug: 'amp-meters',
                items: 3,
            },
            {
                name: 'Junctions Boxes',
                slug: 'junction-boxes',
                items: 1,
            },
            {
                name: 'Rectifiers',
                slug: 'rectifiers',
                items: 2,
            },
            { name: 'Wire & Cable', slug: 'wire-cable', items: 1 },
        ],
    },
    {
        name: 'Testing Equipment',
        slug: 'testing-equipment',
        items: 12,
        children: [
            {
                name: 'Anode Test Kits',
                slug: 'anode-test-kits',
                items: 1,
            },
            {
                name: 'Test Poles',
                slug: 'test-pole',
                items: 2,
            },
            {
                name: 'Test Stations',
                slug: 'test-station',
                items: 3,
            },
            {
                name: 'Reference Electrodes',
                slug: 'reference-electrodes',
                items: 4,
            },
            {
                name: 'Multimeter',
                slug: 'multi-meter',
                items: 1,
            },
            {
                name: 'Shunts',
                slug: 'shunts',
                items: 1,
            },
        ],
    },
    {
        name: 'Connections & Splicing',
        slug: 'connections-splicing',
        items: 15,
        children:[
            {
                name: 'Splicing',
                slug: 'splicing',
                items: 6,
            },
            {
                name: 'Exothermic Welds',
                slug: 'exothermic',
                items: 6,
            },
        ]
    },
];

function walkTree(defs, parent = null) {
    let list = [];
    const tree = defs.map((def) => {
        const category = {
            id: getId(),
            name: def.name,
            slug: def.slug,
            image: def.image || null,
            items: def.items || 0,
            customFields: {},
            parent,
            children: [],
        };

        const [childrenTree, childrenList] = walkTree(
            def.children || [],
            category,
        );

        category.children = childrenTree;
        list = [...list, category, ...childrenList];

        return category;
    });

    return [tree, list];
}

export function prepareCategory(category, depth) {
    let children;

    if (depth && depth > 0) {
        children = category.children.map((x) => prepareCategory(x, depth - 1));
    }
    return JSON.parse(
        JSON.stringify({
            ...category,
            parent: category.parent ? prepareCategory(category.parent) : null,
            children,
        }),
    );
}

export const [categoriesTreeData, categoriesListData] = walkTree(categoriesDef);
