export default {
    name: 'Anode Sales Company',
    fullName: 'Anode Sales Company - Trusted Cathodic Protection Materials',
    author: {
        name: 'Abel',
        profile_url: 'https://abeltsanchez.com',
        facebook_url: 'https://www.facebook.com/anodesalesco/',
        youtube_url: 'https://www.youtube.com/channel/UCIACRlEU0Lfz4vaM2vUqNbw',
        instagram_url: 'https://www.instagram.com/anodesalesco/',
    },
    contacts: {
        address: '124 22nd Court, Grand Junction, CO 81501',
        email: 'info@anodesystems.com',
        phone: '(970) 243-4149',
    },
};
